import React, { useEffect, useState } from 'react';

import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Select,
  Space,
  Spin,
} from 'antd';
import {
  maxValueMessage,
  requiredValueMessage,
} from '../../../../../utilities/admin_validation_messages';
import {
  percentageInputFormat,
  percentageInputParser,
} from '../../../../../utilities/textTools';

const SELECT_PLACE_HOLDER = 'Seleccionar...';

const tipoBeneficio = [
  { key: 'dias', description: 'Días' },
  { key: 'tasa', description: 'Tasa' },
];

const estados = [
  { key: 'A', description: 'Activo' },
  { key: 'I', description: 'Inactivo' },
];

const decision = [
  { key: 'S', description: 'Si' },
  { key: 'N', description: 'No' },
];

const tipoValorDesc = [
  { key: 'BASE', description: 'Base' },
  { key: 'NETO', description: 'Neto' },
];

const tipoComision = [
  { key: 'COMISION', description: 'Comisión' },
  { key: 'INTERMEDIACION', description: 'Intermediación' },
];

const detalleOferta = [
  { key: 'P', description: 'Parcial' },
  { key: 'T', description: 'Total' },
];

const tipoCompensacionNC = [
  { key: 'MAYOR_FECHA', description: 'De Mayor a Menor Fecha de Vencimiento' },
  { key: 'MENOR_FECHA', description: 'De Menor a Mayor Fecha de Vencimiento' },
];

export const initialAgreementValuesForm = {
  nombre: '',
  cdTipoConvenio: undefined,
  cdEstado: undefined,
  cdTipoValorDesc: 'NETO',
  cdTipoComision: undefined,
  cdTipoCompensacionNC: undefined,
  cdAsociarNotas: undefined,
  cdTipoBeneficioPg: undefined,
  nmDiasMinVenc: '',
  nmDiasAdMora: '',
  nmMinPrepago: '',
  poMaxPrepago: '',
  viaPagoExp: '',
  viaPagoProv: '',
  cdTipoCobro: undefined,
  cdRedondearFactura: undefined,
  dsConvenio: '',
  cdDetalleOferta: undefined,
  snEventoFetv: undefined,
  cdMoneda: undefined,
  cdTipoFe: undefined,
};

const rules = {
  nombre: [
    { required: true, message: requiredValueMessage() },
    { max: 150, message: maxValueMessage(150) },
  ],
  cdTipoConvenio: [{ required: true, message: requiredValueMessage() }],
  cdEstado: [{ required: true, message: requiredValueMessage() }],
  cdTipoValorDesc: [{ required: true, message: requiredValueMessage() }],
  cdTipoComision: [{ required: true, message: requiredValueMessage() }],
  cdTipoCompensacionNC: [{ required: true, message: requiredValueMessage() }],
  cdTipoBeneficioPg: [{ required: true, message: requiredValueMessage() }],
  nmDiasMinVenc: [{ required: true, message: requiredValueMessage() }],
  nmDiasAdMora: [{ required: true, message: requiredValueMessage() }],
  nmMinPrepago: [{ required: true, message: requiredValueMessage() }],
  poMaxPrepago: [{ required: true, message: requiredValueMessage() }],
  viaPagoExp: [{ max: 1, message: maxValueMessage(1) }],
  viaPagoProv: [{ max: 1, message: maxValueMessage(1) }],
  dsConvenio: [
    { required: true, message: requiredValueMessage() },
    { max: 400, message: maxValueMessage(400) },
  ],
  cdMoneda: [{ required: true, message: requiredValueMessage() }],
  cdTipoFe: [{ required: true, message: requiredValueMessage() }],
};

const BasicDataForm = ({
  onFinish,
  data = initialAgreementValuesForm,
  tipoConvenios,
  tipoCobros,
  pagadorSelect,
  handleOnSearchPagador,
  edit = false,
  children,
  monedas,
  tiposFe,
}) => {
  const [form] = Form.useForm();
  const [, forceUpdate] = useState({});
  const [cdTipoConvenio, setCdTipoConvenio] = useState('');

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    setCdTipoConvenio(data.cdTipoConvenio);
    form.setFieldsValue({
      ...data,
    });
  }, [form, data]);

  const handleCdTipoConvenioChange = (cdTipoConvenio) => {
    setCdTipoConvenio(cdTipoConvenio);
  };

  return (
    <Form
      name='content_form'
      layout='vertical'
      form={form}
      onFinish={onFinish}
      initialValues={initialAgreementValuesForm}
    >
      <Form.Item name='scConvenio' hidden='true'>
        <Input />
      </Form.Item>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información general
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col flex='auto'>
          <Form.Item
            label='NOMBRE DEL CONVENIO'
            name='nombre'
            rules={rules['nombre'] || ''}
          >
            <Input />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoConvenio'
            label='TIPO DE CONVENIO'
            rules={rules['cdTipoConvenio'] || ''}
          >
            <Select
              placeholder={SELECT_PLACE_HOLDER}
              allowClear
              onChange={handleCdTipoConvenioChange}
            >
              {tipoConvenios.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='cdEstado'
            label='ESTADO'
            rules={rules['cdEstado'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {estados.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información del pagador
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col flex='auto'>
          {edit === false && (
            <Form.Item name='scPagador' label='PAGADOR'>
              <Select
                placeholder={SELECT_PLACE_HOLDER}
                showSearch
                allowClear
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                onSearch={(value) => handleOnSearchPagador(value)}
                notFoundContent={
                  pagadorSelect.fetching ? <Spin size='small' /> : null
                }
              >
                {pagadorSelect.options.map((d) => (
                  <Select.Option key={d.codigo}>{d.descripcion}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          )}
          {edit && (
            <>
              <Form.Item name='nombrePagador' label='PAGADOR'>
                <Input readOnly />
              </Form.Item>
              <Form.Item name='scPagador' hidden='true'>
                <Input readOnly />
              </Form.Item>
            </>
          )}
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoValorDesc'
            label='APLICAR DESCUENTO SOBRE'
            rules={rules['cdTipoValorDesc'] || ''}
          >
            <Radio.Group buttonStyle='solid'>
              {tipoValorDesc.map((item) => (
                <Radio.Button
                  key={item.key}
                  value={item.key}
                  className='radio-btn'
                >
                  {item.description}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='cdTipoComision'
            label='TIPO DE COMISIÓN'
            rules={rules['cdTipoComision'] || ''}
          >
            <Radio.Group buttonStyle='solid'>
              {tipoComision.map((item) => (
                <Radio.Button
                  key={item.key}
                  value={item.key}
                  className='radio-btn'
                >
                  {item.description}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            name='cdTipoCompensacionNC'
            label='TIPO COMPENSACIÓN NOTAS'
            rules={rules['cdTipoCompensacionNC'] || ''}
          >
            <Radio.Group buttonStyle='solid'>
              <Row justify='center' align='middle'>
                {tipoCompensacionNC.map((item) => (
                  <Col flex='auto'>
                    <Radio.Button
                      key={item.key}
                      value={item.key}
                      className='radio-btn'
                    >
                      {item.description}
                    </Radio.Button>
                  </Col>
                ))}
              </Row>
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdAsociarNotas'
            label='ASOCIAR NOTAS'
            rules={rules['cdAsociarNotas'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {decision.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        {(cdTipoConvenio === 'confirming' ||
          cdTipoConvenio === 'factoring') && (
          <Col span={12}>
            <Form.Item
              name='cdTipoBeneficioPg'
              label='TIPO DE BENEFICIO QUE SE DARÁ AL PAGADOR'
              rules={rules['cdTipoBeneficioPg'] || ''}
            >
              <Radio.Group buttonStyle='solid'>
                {tipoBeneficio.map((item) => (
                  <Radio.Button
                    key={item.key}
                    value={item.key}
                    className='radio-btn'
                  >
                    {item.description}
                  </Radio.Button>
                ))}
              </Radio.Group>
            </Form.Item>
          </Col>
        )}
      </Row>
      <Row gutter={16}>
        {(cdTipoConvenio === 'confirming' ||
          cdTipoConvenio === 'factoring') && (
          <Col span={12}>
            <Form.Item
              label='DÍAS DE COLCHÓN MORA'
              name='nmDiasAdMora'
              rules={rules['nmDiasAdMora'] || ''}
            >
              <InputNumber min={0} max={999} style={{ width: '100%' }} />
            </Form.Item>
          </Col>
        )}
        <Col span={12}>
          <Form.Item
            label='LÍMITE DÍAS VENCIMIENTO'
            name='nmDiasMinVenc'
            rules={rules['nmDiasMinVenc'] || ''}
          >
            <InputNumber min={0} max={999} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información para calcular prepago
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label='DÍAS MÍNIMOS'
            name='nmMinPrepago'
            rules={rules['nmMinPrepago'] || ''}
          >
            <InputNumber min={0} max={99} style={{ width: '100%' }} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='PORCENTAJE MÁXIMO'
            name='poMaxPrepago'
            rules={rules['poMaxPrepago'] || ''}
          >
            <InputNumber
              min={0}
              max={100}
              precision={2}
              parser={percentageInputParser}
              formatter={percentageInputFormat}
              style={{ width: '100%' }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información de la vía de pago
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            label='VIA DE PAGO EXPONENCIAL'
            name='viaPagoExp'
            rules={rules['viaPagoExp'] || ''}
          >
            <Input maxLength={1} />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            label='VIA DE PAGO PROVEEDOR'
            name='viaPagoProv'
            rules={rules['viaPagoProv'] || ''}
          >
            <Input maxLength={1} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col>
          <Space align='baseline' className='sub-title'>
            Información adicional
          </Space>
        </Col>
      </Row>
      <Divider />
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdTipoCobro'
            label='TIPO COBRO'
            rules={rules['cdTipoCobro'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {tipoCobros.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='cdRedondearFactura'
            label='REDONDEAR FACTURA'
            rules={rules['cdRedondearFactura'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {decision.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='snEventoFetv'
            label='EVENTOS PREVIOS'
            rules={rules['snEventoFetv'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER} allowClear>
              {decision.map((item) => (
                <Select.Option key={item.key} value={item.key}>
                  {item.description}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item name='cdDetalleOferta' label='DETALLE OFERTA'>
            <Radio.Group buttonStyle='solid'>
              {detalleOferta.map((item) => (
                <Radio.Button
                  key={item.key}
                  value={item.key}
                  className='radio-btn'
                >
                  {item.description}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name='cdMoneda'
            label='MONEDA'
            rules={rules['cdMoneda'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER}>
              {monedas.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name='cdTipoFe'
            label='Sistema de Facturación Electrónica'
            rules={rules['cdTipoFe'] || ''}
          >
            <Select placeholder={SELECT_PLACE_HOLDER}>
              {tiposFe.map((item) => (
                <Select.Option key={item.codigo} value={item.codigo}>
                  {item.descripcion}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col span={24}>
          <Form.Item
            label='DESCRIPCIÓN DEL CONVENIO'
            name='dsConvenio'
            rules={rules['dsConvenio'] || ''}
          >
            <Input.TextArea autoSize={{ minRows: 4 }} maxLength={400} />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={16}>
        {children}
        <Col span={6}>
          <Button
            style={{ width: '90%' }}
            className='btn btn-green'
            type='primary'
            htmlType='submit'
          >
            Grabar
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default BasicDataForm;
