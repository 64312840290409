import moment from 'moment';
import { DATE_FORMAT } from './messages';

export const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  var string = s.split(' ');
  var capitalizedText = '';
  string.forEach((word) => {
    capitalizedText += capitalizeOneWord(word) + ' ';
  });
  return capitalizedText;
};

const capitalizeOneWord = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const currencyFormat = (num, decimals = 2) => {
  const text = numberFormat(num, decimals);
  return `$ ${text}`;
};

export const CurrencyFormat = ({ value, localizacion, currency }) => {
  if (localizacion && currency) {
    return Intl.NumberFormat(localizacion, {
      style: 'currency',
      currency: currency,
    }).format(value);
  } else {
    return Intl.NumberFormat('default', {
      style: 'currency',
      currency: 'COP',
    }).format(value);
  }
};

export const numberFormat = (num, decimals = 2) => {
  if (isNaN(num)) {
    return '';
  }
  num = Number(num);
  num = num.toFixed(decimals);
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const percentageFormat = (num, decimals = 2) => {
  const text = numberFormat(num, decimals);
  return `${text}%`;
};

export const sorterText = (a, b) => {
  const nameA = a ? a.toUpperCase() : '';
  const nameB = b ? b.toUpperCase() : '';
  try {
    return nameA.localeCompare(nameB);
  } catch (e) {
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    return 0;
  }
};

export const dateFormat = (date) => {
  return date.replace(/^(\d{4})-(\d{2})-(\d{2})$/g, '$3/$2/$1');
};

export const sorterDate = (a, b) => {
  a = a == undefined ? '' : a;
  b = b == undefined ? '' : b;
  const am = a ? moment(a, DATE_FORMAT) : '';
  const bm = b ? moment(b, DATE_FORMAT) : '';
  return am - bm;
};

export const currencyInputFormat = (value) => {
  return `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const currencyInputParser = (numText) => {
  return numText.replace(/\$\s?|(,*)/g, '');
};

export const percentageInputFormat = (value) => {
  return `${value}%`;
};

export const percentageInputParser = (numText) => {
  return numText.replace('%', '');
};
