import React from 'react'
import './finish.scss'
import '../billing.scss'
import '../../../../styles/standard.scss'
import { Row, Col, Progress, Card, Input, Button, notification, Spin } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import Man from '../../../../assets/img/success.png';
import store from "../../../../store/index";
import { connect } from "react-redux";
import { Redirect } from 'react-router-dom'
import {
  nextStep,
  chargeallNextStep,
  selectedBills,
  chargeallSelectedBills,
  programSelectedBills,
  programNextStep,
  reloadNotifications
} from '../../../../store/actions/proveedor/billingActions'
import { getUserAction } from '../../../../store/actions/proveedor/affitokenActtions'
import { enviarComprobante, descargarComprobante, listarTrackingUnico } from '../../../../services/proveedor/reportServices'
import { loadReportSummaryAction } from '../../../../store/actions/proveedor/reportsActions'
import history from '../../../../services/history'

class Finish extends React.Component {

  constructor(props) {
    super(props)
    this.state = {
      redirectToTracking: false,
      email: '',
      loading: false,
      estado: 'EP'
    }

  }

  componentDidMount() {
    const { company, trackingId } = store.getState().billing
    const authtoken = store.getState().auth.auth.access_token;
    if (company) {
      setTimeout(() => {
        this.props.loadReportSummaryAction(authtoken, company)
        this.props.reloadNotifications(true);
      }, 120000);
      setTimeout(() => {
        this.loadTracking()
        this.props.reloadNotifications(true);
      }, 18000);
      setTimeout(() => {
        this.loadTracking()
        this.props.reloadNotifications(true);
      }, 36000);
      setTimeout(() => {
        this.loadTracking()
        this.props.reloadNotifications(true);
      }, 54000);
      this.props.getUserAction({ authtoken, company })
      if (this.props.module === 'assisted') {
        this.props.chargeallSelectedBills({
          chargeallSelectedBills: [], chargeallTotalSelectedValue: 0
        })
      } else if (this.props.module === 'program') {
        this.props.programSelectedBills({
          programSelectedBills: [], programTotalSelectedValue: 0
        })
      } else {
        this.props.selectedBills({
          totalSelectedValue: 0,
          selectedKeys: [],
          selectedBills: []
        })
      }
    }
  }

  componentDidUpdate(props, state) {
    if (props.affitoken !== this.props.affitoken) {
      this.setState({
        ...this.state,
        email: this.props.affitoken.user.email
      })
    }
  }

  loadTracking = () => {
    const { company } = store.getState().billing
    const { trackingId } = store.getState().billing;
    const token = store.getState().auth.auth.access_token;
    listarTrackingUnico(token, company, trackingId).then(response => {
      if (Array.isArray(response.data)) {
        let trackingObj = response.data.find(x => x.scTracking == trackingId)
        this.setState({
          ...this.state,
          estado: trackingObj.estado
        })
      }

    })
  }

  sendVoucher = () => {
    const { company } = store.getState().billing
    const { trackingId } = store.getState().billing;
    const token = store.getState().auth.auth.access_token;
    this.setState({
      ...this.state,
      loading: true
    })
    enviarComprobante(token, company, trackingId, this.state.email).then(response => {
      if (response.message) {
        this.setState({
          ...this.state,
          loading: false
        })
        notification.success({
          message: 'Correo enviado',
          description: `${response.message}`,
          duration: 5
        });
      }
    })
  }

  redirect(route) {
    if (route === 'chargeall' || this.props.module === 'chargeall') {
      this.props.chargeallNextStep(0);
    } else if (route === 'billing' || this.props.module === 'bill') {
      this.props.nextStep(0);
    } else if (route === 'program' || this.props.module === 'program') {
      this.props.programNextStep(0);
    }
    if (route === 'tracking') {
      history.push(`${process.env.PUBLIC_URL}/${route}`);
    } else {
      this.props.stepHandler();
    }
  }

  componentWillUnmount() {
    if (this.props.module === 'chargeall') {
      this.props.chargeallNextStep(0);
    } else if (this.props.module === 'bill') {
      this.props.nextStep(0);
    } else if (this.props.module === 'program') {
      this.props.programNextStep(0);
    }
  }

  downloadVoucher = () => {
    const { company } = store.getState().billing
    const { trackingId } = store.getState().billing;
    const token = store.getState().auth.auth.access_token;
    this.setState({
      ...this.state,
      loading: true
    })
    descargarComprobante(token, company, trackingId).then(response => {
      this.setState({
        ...this.state,
        loading: false
      })
      const url = window.URL.createObjectURL(new Blob([response], { type: 'application/pdf' }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Comprobante ${trackingId}.pdf`);
      document.body.appendChild(link);
      link.click();
    })
  }

  updateEmail = (e) => {
    this.setState({
      ...this.state,
      email: e.target.value
    })
  }

  ordinalState(status) {
    switch (status) {
      case 'EP':
        return 'first'
      case 'A':
        return 'second'
      case 'C':
        return 'third'
      case 'D':
        return 'fourth'
      default:
        return ''
    }
  }

  validateStatus(status) {
    switch (status) {
      case 'EP':
        return 25
      case 'A':
        return 50
      case 'C':
        return 75
      case 'D':
        return 100
      case 'P':
        return 0
      case 'F':
        return 0
      case 'N':
        return 0
      default:
        return 0
    }
  }

  render() {
    const { trackingId } = store.getState().billing;
    return (
      <div className="finish-style">
        <Spin tip="Cargando..." spinning={this.state.loading}>
          <Card className="card-shadow card-radius default-border first-message">
            <div className="container">
              <Row className="finish-message">
                <Col>
                  <img src={Man} alt="" />
                </Col>
                <Col>
                  <div className="title">
                    ¡Transacción en proceso!
                </div>
                  <div className="description">
                  Para ver el estado de tus facturas, revisa el traking numero: {trackingId}
                  </div>
                  <div className="description">
                  En caso de tener notas creditos, estas serán descontadas al momento de la negociación.                  </div>
                </Col>
              </Row>
              <Row className="state-container">
                <Col span={24}>
                  <Row>
                    {this.state.estado == 'P' || this.state.estado == 'F' || this.state.estado == 'F' || this.state.estado == 'N' ?
                      (<div className="ant-progress ant-progress-line ant-progress-status-normal ant-progress-default track-progress-bar">
                        <div className="ant-progress-inner">
                          <div className="progress-inner-empty">
                            <p >{this.state.estado == 'F' ? 'FALLIDO' : this.state.estado == 'P' ? 'PROGRAMADO' : this.state.estado == 'N' ? 'ANULADO' : 'SIN ESTADO'}</p>
                          </div>
                        </div>
                      </div>)
                      :
                      (<Progress className={"track-progress-bar " + this.ordinalState(this.state.estado)}
                        id={trackingId}
                        percent={this.validateStatus(this.state.estado)}
                        showInfo={false}
                      />)}
                  </Row>
                  <Row className="track-percent-desc">
                    <Col span={6}>
                      <p>En proceso</p>
                    </Col>
                    <Col span={6}>
                      <p>Completado</p>
                    </Col>
                    <Col span={6}>
                      <p>Confirmado</p>
                    </Col>
                    <Col span={6}>
                      <p>Desembolsado</p>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="voucher-container">
                <Col span={24}>
                  <Row>
                    <Col className="title">Enviar comprobante</Col>
                  </Row>
                  <Row className="mail-input" gutter={16}>
                    <Col span={14}>
                      <Input value={this.state.email} onChange={this.updateEmail} placeholder="sample@mail.com.co" />
                    </Col >
                    <Col span={6} offset={2}>
                      <Button className="send-button" disabled = {this.state.estado === 'EP'} onClick={this.sendVoucher}>Enviar</Button>
                    </Col>
                  </Row>
                  <Row className="actions" gutter={16}>
                    <Col span={8}>
                      <Button className="download-button" disabled = {this.state.estado === 'EP'}  onClick={this.downloadVoucher}>Descargar comprobante</Button>
                    </Col>
                    <Col span={8} >
                      {this.props.module === 'assisted' ?
                        <Button className="download-button" onClick={() => this.redirect('chargeall')}>Regresar a cobrar todo</Button>
                        :
                        this.props.module === 'program' ?
                          <Button className="download-button" onClick={() => this.redirect('program')}>Regresar a programar</Button>
                          :
                          <Button className="download-button" onClick={() => this.redirect('billing')}>Regresar a ofertas</Button>
                      }
                    </Col>
                    <Col span={8} >
                      <Button className="track-button" onClick={() => this.redirect('tracking')}>Ir al tracking</Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </div>
          </Card>
        </Spin>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    authentication: {
      authtoken: state.auth.auth.access_token,
      company: state.billing.company,
    },
    affitoken: state.affitoken,
    billing: state.billing
  }
}

export default connect(mapStateToProps, { nextStep, getUserAction, chargeallNextStep, selectedBills, chargeallSelectedBills, programSelectedBills, programNextStep, loadReportSummaryAction, reloadNotifications })(Finish);