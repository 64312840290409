import React from 'react';
import {
  Button,
  Card,
  Col,
  Modal,
  Progress,
  Row,
  Spin,
  Switch,
  Table,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import trackingImg from '../../../assets/img/tracking.png'
import { listarTracking, descargarComprobante, listarTrackingDetalleFacturas, listarTrackingDetalleNotasCredito, cancelTracking, cancelTrackingByBill } from '../../../services/proveedor/reportServices'
import { connect } from "react-redux";
import store from "../../../store/index";
import './tracking.scss';

class Tracking extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      trackingList: [],
      loading: false,
      modal: false,
      trackingDetail: [],
      changeDataType: false,
      trackingDetailActive: 0,
      modalTitle: 'Detalle',
    };
  }

  trackingDetailBillsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => a.scFactura - b.scFactura,
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmision',
      sorter: (a, b) => Date.parse(a.feEmision) - Date.parse(b.feEmision),
      render: (text) => this.formatDate(text, 0),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimiento',
      sorter: (a, b) =>
        Date.parse(a.feVencimiento) - Date.parse(b.feVencimiento),
      render: (text) => this.formatDate(text, 0),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFactura',
      sorter: (a, b) => a.scFactura - b.scFactura,
      render: (text) => <span>$ {this.formatNumber(text)}</span>,
      showSorterTooltip: false,
    },
    {
      title: 'OFERTA COMPRA',
      dataIndex: 'vrOferta',
      sorter: (a, b) => a.scFactura - b.scFactura,
      render: (text) => (
        <span className='offer-value'>$ {this.formatNumber(text)}</span>
      ),
      showSorterTooltip: false,
    },
    {
      title: 'ESTADO',
      dataIndex: 'estadoTracking',
      sorter: (a, b) => a.estadoTracking - b.estadoTracking,
      showSorterTooltip: false,
    },
    {
      title: 'ANULAR',
      dataIndex: '',
      render: (record) => 
      record.feProgramacion && record.estadoTracking === 'Progamado' || record.feProgramacion && record.estadoTracking === 'Fallido'  ? (
      <Button type='primary'  className='form-btn btn-radius'
       onClick={() => this.cancelTrackingByBill(record)} >Anular Factura</Button>
      ): null,
    showSorterTooltip: false,
    }

  ]

  trackingDetailCreditsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFacturaRest',
      sorter: (a, b) => a.cdFacturaRest - b.cdFacturaRest,
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmisionRest',
      render: (text) => text,
      sorter: (a, b) =>
        Date.parse(a.feEmisionRest) - Date.parse(b.feEmisionRest),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimientoRest',
      render: (text) => text,
      sorter: (a, b) =>
        Date.parse(a.feVencimientoRest) - Date.parse(b.feVencimientoRest),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFacturaRest',
      render: (value) => '$ ' + this.formatNumber(value),
      sorter: (a, b) => a.vrFacturaRest - b.vrFacturaRest,
      showSorterTooltip: false,
    },
    {
      title: 'ESTADO',
      dataIndex: 'estadoTracking',
      sorter: (a, b) => a.estadoTracking - b.estadoTracking,
      showSorterTooltip: false,
    },
  ];

  componentDidMount() {
    this.props.menuHandler('6');
    this.setState({
      ...this.state,
      loading: true,
    });
    this.loadTracking();
  }

  updateModalVisibility(boolean, trackingId) {
    if (boolean) {
      if (trackingId !== 0) {
        this.setState({
          ...this.state,
          loading: true,
          trackingDetailActive: trackingId,
        });
        const selectedCompany = store.getState().billing.company;
        const token = store.getState().auth.auth.access_token;
        listarTrackingDetalleFacturas(token, selectedCompany, trackingId).then(
          (response) => {
            this.setState({
              ...this.state,
              trackingDetail: {
                bills: response.data,
              },
              modalTitle: `Detalle # ${trackingId}`,
            });
            listarTrackingDetalleNotasCredito(
              token,
              selectedCompany,
              trackingId
            ).then((response) => {
              this.setState({
                ...this.state,
                trackingDetail: {
                  ...this.state.trackingDetail,
                  credits: response.data,
                },
                loading: false,
                modal: true,
              });
            });
          }
        );
      }
    } else {
      this.setState({
        ...this.state,
        trackingDetailActive: 0,
        changeDataType: false,
      });
    }
    this.setState({
      ...this.state,
      modal: boolean,
    });
  }

  changeTable(boolean) {
    this.setState({
      ...this.state,
      changeDataType: !boolean,
    });
  }

  componentDidUpdate(props, state) {
    if (props.billing.company !== this.props.billing.company) {
      this.setState({
        ...this.state,
        loading: true,
      });
      const selectedCompany = this.props.billing.company;
      const token = store.getState().auth.auth.access_token;
      this.loadTracking();
    }
  }

  formatDate(date, type) {
    var arrDate = date.split('T');
    if (type === 1) {
      const arrHour = arrDate[type].split('.');
      arrDate[type] = arrHour[0];
    }
    return arrDate[type];
  }

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  validateStatus(status) {
    switch (status) {
      case 'EP':
        return 25;
      case 'A':
        return 50;
      case 'C':
        return 75;
      case 'D':
        return 100;
      case 'P':
        return 0;
      case 'F':
        return 0;
      case 'N':
        return 0;
      default:
        return 0;
    }
  }

  downloadVoucher(trackingId) {
    const { company } = store.getState().billing;
    const token = store.getState().auth.auth.access_token;
    descargarComprobante(token, company, trackingId).then((response) => {
      const url = window.URL.createObjectURL(
        new Blob([response], { type: 'application/pdf' })
      );
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Comprobante ${trackingId}.pdf`);
      document.body.appendChild(link);
      link.click();
    });
    return false;
  }

  cancelTracking(trackingId) {
    this.setState({
      ...this.state,
      loading: true
    })
    let selectedCompany = store.getState().billing.company;
    let token = store.getState().auth.auth.access_token;
    cancelTracking(token, selectedCompany, trackingId).then((response) => {
      this.loadTracking();
    });
  }

  cancelTrackingByBill(data) {
    this.setState({
      ...this.state,
      loading: true
    })
    let selectedCompany = store.getState().billing.company;
    let token = store.getState().auth.auth.access_token;
    cancelTrackingByBill(token, selectedCompany,data.scTracking, data.scFactura).then(response => {
      this.loadTracking()
    })
  }

  loadTracking() {
    const selectedCompany = store.getState().billing.company;
    if (selectedCompany !== '') {
      const token = store.getState().auth.auth.access_token;
      listarTracking(token, selectedCompany).then((response) => {
        if (response) {
          this.setState({
            ...this.state,
            trackingList: response.data,
            loading: false,
          });
        }
      });
      this.updateModalVisibility()
    }
  }

  ordinalState(status) {
    switch (status) {
      case 'EP':
        return 'first';
      case 'A':
        return 'second';
      case 'C':
        return 'third';
      case 'D':
        return 'fourth';
      default:
        return '';
    }
  }
  render() {
    const { trackingList } = this.state;
    return (
      <div className='content-margin tracking'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <Row justify={'center'} className='track-imgmargin'>
            <img src={trackingImg} alt='' />
          </Row>
          {Array.isArray(trackingList) && trackingList.length > 0 && (
            <div>
              {trackingList.map((tracking) => (
                <Row justify={'center'} key={tracking.scTracking}>
                  <Col span={20}>
                    <Card className='card-radius card-shadow track-card-margin'>
                      <Row align={'middle'}>
                        <Col span={18}>
                          <Title level={3} className='track-txnumber'>
                            Transacción #{tracking.scTracking}
                          </Title>
                        </Col>
                        <Col span={6}>
                          <Button
                            className='button-link'
                            onClick={() =>
                              this.updateModalVisibility(
                                true,
                                tracking.scTracking
                              )
                            }
                          >
                            VER DETALLE
                          </Button>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <p>
                            Realizada por: {tracking.nombrePersonaModifica}
                            <br />
                            {`Fecha ingreso: ${tracking.fechaIngreso}`}
                            <br />
                            {tracking.fechaProgramacion
                              ? `Fecha programación: ${tracking.fechaProgramacion} `
                              : ''}
                          </p>
                        </Col>
                      </Row>
                      <Row>
                        {tracking.estado == 'P' ||
                        tracking.estado == 'F' ||
                        tracking.estado == 'F' ||
                        tracking.estado == 'N' ? (
                          <div className='ant-progress ant-progress-line ant-progress-status-normal ant-progress-default track-progress-bar'>
                            <div className='ant-progress-inner'>
                              <div className='progress-inner-empty'>
                                <p>
                                  {tracking.estado == 'F'
                                    ? 'FALLIDO'
                                    : tracking.estado == 'P'
                                    ? 'PROGRAMADO'
                                    : tracking.estado == 'N'
                                    ? 'ANULADO'
                                    : 'SIN ESTADO'}
                                </p>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <Progress
                            className={
                              'track-progress-bar ' +
                              this.ordinalState(tracking.estado)
                            }
                            id={tracking.scTracking}
                            percent={this.validateStatus(tracking.estado)}
                            showInfo={false}
                          />
                        )}
                      </Row>
                      <Row className='track-percent-desc'>
                        <Col span={6}>
                          <p>En proceso</p>
                        </Col>
                        <Col span={6}>
                          <p>Completado</p>
                        </Col>
                        <Col span={6}>
                          <p>Confirmado</p>
                        </Col>
                        <Col span={6}>
                          <p>Desembolsado</p>
                        </Col>
                      </Row>
                      <Row>
                        {tracking.fechaProgramacion &&
                        tracking.estado === 'P' || tracking.fechaProgramacion && tracking.estado === 'F'   ? (
                          <Col span={10} justify='start'>
                            <Button
                              className='button-link'
                              onClick={() =>
                                this.cancelTracking(tracking.scTracking)
                              }
                            >
                              ANULAR TRANSACCION
                            </Button>
                          </Col>
                        ) : (
                          <Col span={10} justify='start'></Col>
                        )}
                        <Col span={14} justify='end'>
                          <Button
                            className='button-link'
                            disabled = {tracking.estado === 'EP'}
                            onClick={() =>
                              this.downloadVoucher(tracking.scTracking)
                            }
                          >
                            DESCARGAR SOPORTE DE LA TRANSACCIÓN
                          </Button>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              ))}
            </div>
          )}
          <Modal
            className='tracking-modal'
            centered
            title={this.state.modalTitle}
            destroyOnClose={true}
            visible={this.state.modal}
            onOk={() => this.updateModalVisibility(false, 0)}
            onCancel={() => this.updateModalVisibility(false, 0)}
            footer={null}
            width={1024}
          >
            <Row gutter={16}>
              <Col xl={{ span: 5 }} md={{ span: 6 }} className='select-label'>
                Facturas/Notas crédito
              </Col>
              <Col
                xl={{ span: 5 }}
                md={{ span: 8 }}
                className='switch-container'
              >
                <Switch
                  checkedChildren='Notas crédito'
                  checked={this.state.changeDataType}
                  unCheckedChildren='Facturas'
                  onChange={() => this.changeTable(this.state.changeDataType)}
                />
              </Col>
            </Row>
            <Row gutter={16} className='margin-top'>
              <Col span={24}>
                <Table
                  loading={this.state.loading}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} de ${total} registros`,
                  }}
                  columns={
                    this.state.changeDataType
                      ? this.trackingDetailCreditsColumns
                      : this.trackingDetailBillsColumns
                  }
                  dataSource={
                    this.state.changeDataType
                      ? this.state.trackingDetail.credits
                      : this.state.trackingDetail.bills
                  }
                ></Table>
              </Col>
            </Row>
          </Modal>
        </Spin>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  billing: state.billing,
});

export default connect(mapStateToProps, {})(Tracking);
