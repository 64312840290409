import React, { Component } from "react";
import { Card, Modal } from "antd";

class CondicionesModal extends Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
  }
  handleOnClose = () => {
    const { onClose } = this.props;
    onClose();
  };
  render() {
    const { visible, data } = this.props;

    return (
      <Modal
        title={<h3>Condiciones de {data.nombre}</h3>}
        visible={visible}
        onCancel={this.handleOnClose}
        width={800}
        footer={false}
      >
        <Card className="card-shadow card-radius default-border welcome-message">
          <div>
            <p dangerouslySetInnerHTML={{ __html: data.mensajeFondeador }}></p>
          </div>
        </Card>
      </Modal>
    );
  }
}
export default CondicionesModal;
