import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const exportarArchivoTransunion = (authtoken, idProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/creditos/transunion/${idProveedor}/exportar/xlsx`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

export const exportarArchivoListaControl = (authtoken, idProveedor) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/creditos/listaDeControl/${idProveedor}/exportar/xlsx`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};
