import axios from 'axios';
import { middleWare } from '../middleware';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const ejecutarTareaERP = (authtoken, data) => {
  let filters = formatoFechas(data);
  return middleWare(
    axios({
      url: `${baseUrl}/api/tareas/erp`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: filters,
    })
  );
};

export const ejecutarTareaCupos = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tareas/cupos`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const ejecutarTareaHubspot = (authtoken, data) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tareas/hubspot`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data,
    })
  );
};

export const ejecutarTareaProveedores = (authtoken) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/tareas/proveedores`,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
    })
  );
};

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
