import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import TableReport from '../../../../../components/tableReport/tableReport';
import { listarMonedas } from '../../../../../services/admin/monedasService';
import {
  listarConvenios,
  listarFondeadores,
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../../services/admin/reports/quotasService';
import {
  listarFacturasFondeador,
  listarFacturasFondeadorExcel,
} from '../../../../../services/admin/reportsDiscountedBills';
import { setExcel } from '../../../../../utilities/downloadTools';
import {
  CurrencyFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';
import FundProvidersFilterForm from './fundProvidersFilterForm/fundProvidersFilterForm';

const initialState = {
  form: {
    fechaDesdeCompra: moment(),
    fechaHastaCompra: moment(),
    scGrupo: '',
    scPagador: '',
    scProveedor: '',
    cdFactura: '',
    scConvenio: '',
    scFondeador: '',
    cdMoneda: 'COP',
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 12,
    },
    {
      id: 'totalVrOferta',
      value: '',
      index: 'vrOferta',
      colSpan: 1,
    },
    {
      id: 'totalVrPago',
      value: '',
      index: 'vrPago',
      colSpan: 1,
    },
    {
      id: 'totalVrDesembolso',
      value: '',
      index: 'vrDesembolso',
      colSpan: 1,
    },
    {
      id: 'totalVrBeneficioFondeador',
      value: '',
      index: 'vrBeneficioFondeador',
      colSpan: 1,
    },
    {
      id: 'totalVrCostoTransaccionFd',
      value: '',
      index: 'vrCostoTransaccionFd',
      colSpan: 1,
    },
    {
      id: 'totalVrCostoTransaccionPvBase',
      value: '',
      index: 'vrCostoTransaccionPvBase',
      colSpan: 1,
    },
    {
      id: 'totalVrIvaTransaccion',
      value: '',
      index: 'vrIvaTransaccion',
      colSpan: 1,
    },
    {
      id: 'totalVrBeneficioExponencialBase',
      value: '',
      index: 'vrBeneficioExponencialBase',
      colSpan: 1,
    },
    {
      id: 'totalVrIvaComisionCorretaje',
      value: '',
      index: 'vrIvaComisionCorretaje',
      colSpan: 1,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  buttonOptions: [],
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  fondeadorSelect: {
    fetching: false,
    options: [],
  },
  monedaSelect: [],
};

class FundProviders extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: 'NÚMERO FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REPORTADO',
        dataIndex: 'vrFactura',
        sorter: (a, b) => a.vrFactura - b.vrFactura,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR BASE FACTURA',
        dataIndex: 'vrFacturaBase',
        sorter: (a, b) => a.vrFacturaBase - b.vrFacturaBase,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR REDUCIDO',
        dataIndex: 'vrFacturaChReducido',
        sorter: (a, b) => a.vrFacturaChReducido - b.vrFacturaChReducido,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR TOTAL NOTAS',
        dataIndex: 'vrTotalNotas',
        sorter: (a, b) => a.vrTotalNotas - b.vrTotalNotas,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA EMISIÓN',
        dataIndex: 'feEmision',
        sorter: (a, b) => sorterDate(a.feEmision, b.feEmision),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENCIMIENTO',
        dataIndex: 'feVencimiento',
        sorter: (a, b) => sorterDate(a.feVencimiento, b.feVencimiento),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA ESPERADA DE PAGO',
        dataIndex: 'feEstimadaPago',
        sorter: (a, b) => sorterDate(a.feEstimadaPago, b.feEstimadaPago),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENTA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'PLAZO DESCONTADO',
        dataIndex: 'numPlazoPago',
        sorter: (a, b) => a.numPlazoPago - b.numPlazoPago,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR OFERTA',
        dataIndex: 'vrOferta',
        sorter: (a, b) => a.vrOferta - b.vrOferta,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PAGADO',
        dataIndex: 'vrPago',
        sorter: (a, b) => a.vrPago - b.vrPago,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR DESEMBOLSO',
        dataIndex: 'vrDesembolso',
        sorter: (a, b) => a.vrDesembolso - b.vrDesembolso,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'BENEFICIO FONDEADOR ESPERADO',
        dataIndex: 'vrBeneficioFondeador',
        sorter: (a, b) => a.vrBeneficioFondeador - b.vrBeneficioFondeador,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR POR TRANSACCIÓN',
        dataIndex: 'vrCostoTransaccionFd',
        sorter: (a, b) => a.vrCostoTransaccionFd - b.vrCostoTransaccionFd,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'COMISIÓN PROVEEDOR',
        dataIndex: 'vrCostoTransaccionPvBase',
        sorter: (a, b) =>
          a.vrCostoTransaccionPvBase - b.vrCostoTransaccionPvBase,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR IVA TRANSACCIÓN',
        dataIndex: 'vrIvaTransaccion',
        sorter: (a, b) => a.vrIvaTransaccion - b.vrIvaTransaccion,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR PAGADO A EXPONENCIAL',
        dataIndex: 'vrBeneficioExponencialBase',
        sorter: (a, b) =>
          a.vrBeneficioExponencialBase - b.vrBeneficioExponencialBase,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
      {
        title: 'VALOR IVA COMISIÓN CORRETAJE',
        dataIndex: 'vrIvaComisionCorretaje',
        sorter: (a, b) => a.vrIvaComisionCorretaje - b.vrIvaComisionCorretaje,
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchFondeador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { fondeadorSelect } = this.state;

      this.setState({
        ...this.state,
        fondeadorSelect: {
          ...fondeadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarFondeadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          fondeadorSelect: {
            ...fondeadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarConvenios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        grupoSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        scGrupo: value.scGrupo,
        scConvenio: value.scConvenio,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        scFondeador: value.scFondeador,
        cdFactura: value.cdFactura,
        fechaDesdeCompra: value.fechaRango[0],
        fechaHastaCompra: value.fechaRango[1],
        cdMoneda: value.cdMoneda,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    this.setState({
      ...this.state,
      resultado: [],
      resumen: initialState.resumen,
      loading: true,
    });

    try {
      const response = await listarFacturasFondeador(
        this.props.authToken,
        this.getFiltro()
      );

      const resumen = this.state.resumen.map((res) => {
        if (response.metadata.sumatorias[res.id] !== undefined) {
          res.value = (
            <span className='format-number'>
              {CurrencyFormat({
                value: response.metadata.sumatorias[res.id],
                localizacion: response.metadata.localizacion,
                currency: response.metadata.cdMoneda,
              })}
            </span>
          );
        }

        return res;
      });

      this.setState({
        ...this.state,
        resultado: response.data,
        total: response.metadata.count,
        resumen: resumen,
        loading: false,
      });
    } catch {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  exportExcel = async () => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await listarFacturasFondeadorExcel(
        this.props.authToken,
        this.getFiltro()
      );
      setExcel(response, `Facturas_fondeador`);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  componentDidMount() {
    this.init();
  }

  init = async () => {
    await this.addSelect();
    await this.searchData();
  };

  addSelect = async () => {
    this.setState({ ...this.state, loading: true });

    try {
      const responseMoneda = await listarMonedas(this.props.authToken, {
        limit: 300,
      });

      this.setState({
        ...this.state,
        monedaSelect: responseMoneda.data,
      });
    } catch {
      this.setState({ ...this.state, loading: false });
    }
  };

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      grupoSelect,
      pagadorSelect,
      proveedorSelect,
      convenioSelect,
      fondeadorSelect,
      monedaSelect,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <FundProvidersFilterForm
            grupoSelect={grupoSelect}
            pagadorSelect={pagadorSelect}
            proveedorSelect={proveedorSelect}
            convenioSelect={convenioSelect}
            fondeadorSelect={fondeadorSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnSearchGrupo={this.handleOnSearchGrupo}
            handleOnSearchPagador={this.handleOnSearchPagador}
            handleOnSearchFondeador={this.handleOnSearchFondeador}
            monedaSelect={monedaSelect}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default FundProviders;
