import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

export const listarMonedas = (authtoken, filtros) => {
  return middleWare(
    axios({
      url: `${baseUrl}/api/listar-monedas?${queryString.stringify({
        ...filtros,
      })}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
