import { Modal } from 'antd';
import equals from 'fast-deep-equal';
import moment from 'moment';
import 'moment/locale/es';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Link, Route, Switch } from 'react-router-dom';

/**Pages */
import Affitoken from '../pages/proveedor/affitoken/affitoken';
import Agreements from '../pages/proveedor/agreements/agreements';
import Billing from '../pages/proveedor/billing/billing';
import BulkLoad from '../pages/proveedor/bulkload/bulkload';
import ChangePassword from '../pages/proveedor/changepass/changepass';
import ChargeAll from '../pages/proveedor/chargeall/chargeall';
import TYC from '../pages/proveedor/content/TYC';
import FAQ from '../pages/proveedor/content/faq';
import HelpCenter from '../pages/proveedor/content/helpcenter';
import DashBoard from '../pages/proveedor/dashboard/dashboard';
import Error from '../pages/proveedor/error/error';
import Person from '../pages/proveedor/person/person';
import Program from '../pages/proveedor/program/program';
import ReportCenter from '../pages/proveedor/reports/reportCenter';
import { NewRequest } from '../pages/proveedor/requests/newRequest';
import Tracking from '../pages/proveedor/tracking/tracking';
import history from '../services/history';
import masterServices from '../services/proveedor/masterServices';

/**components antd */
import {
  BellOutlined,
  CalendarOutlined,
  CheckSquareOutlined,
  ClockCircleOutlined,
  CloudUploadOutlined,
  CopyOutlined,
  DollarOutlined,
  FormOutlined,
  HomeOutlined,
  LogoutOutlined,
  MessageOutlined,
  QuestionCircleOutlined,
  RobotOutlined,
  ScheduleOutlined,
  SettingOutlined,
  ShoppingOutlined,
  SnippetsOutlined,
  SyncOutlined,
  WalletOutlined,
} from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  Drawer,
  Layout,
  Menu,
  Row,
  Select,
  notification,
} from 'antd';
import '../styles/proveedorLayout.scss';
/**assets */
import logo from '../assets/img/logo-side-bar.png';
import userLogo from '../assets/img/user-icon.png';

/**redux */
import AccountStatus from '../pages/proveedor/accountStatus/accountStatus';
import Refunds from '../pages/proveedor/refunds/refunds';
import { MyRequests } from '../pages/proveedor/requests/myRequests';
import { OldRequest } from '../pages/proveedor/requests/oldRequests';
import {
  actualizarEstadoLeido,
  listarNotificaciones,
} from '../services/proveedor/notificationServices';
import {
  refreshToken,
  sessionClose,
  setExpirationTimeout,
} from '../store/actions/authActions';
import { resetAffitokengSteps } from '../store/actions/proveedor/affitokenActtions';
import {
  reloadNotifications,
  resetBillingSteps,
  setCompany,
  setPersonType,
} from '../store/actions/proveedor/billingActions';
import {
  initBillSummaryAction,
  loadReportSummaryAction,
} from '../store/actions/proveedor/reportsActions';
import store from '../store/index';

moment.locale('es');

const { Option } = Select;

const { Header, Sider, Content } = Layout;

const { SubMenu } = Menu;
class ProveedorLayout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuSelectedKey: '1',
      selected_company: '',
      username: '',
      notificationDrawer: false,
      logged: true,
      notifications: [],
      billingResume: [
        {
          icon: SnippetsOutlined,
          value: 0,
          message: 'en 0 Facturas por cobrar',
          size: 7,
        },
        {
          icon: DollarOutlined,
          value: 0,
          message: 'en 0 notas crédito',
          size: 7,
        },
        {
          icon: CheckSquareOutlined,
          value: 0,
          message: 'facturas nuevas hoy',
          size: 7,
        },
      ],
      unreadedNotifications: 0,
      showAuthExpModal: false,
      setTimeoutCloseSession: null,
    };

    this.selectedKeyHandler = this.selectedKeyHandler.bind(this);
  }

  selectedKeyHandler(key) {
    this.setState({
      ...this.state,
      menuSelectedKey: key,
    });
    this.resetSteps();
  }

  resetSteps() {
    this.props.resetBillingSteps();
    this.props.resetAffitokengSteps();
  }

  fecha = moment().format('dddd, LL');

  /**
   * Cards Template
   */

  componentDidMount() {
    if (window.configuredInterval) clearInterval(window.configuredInterval);

    window.configuredInterval = setInterval(() => {
      this.props.setExpirationTimeout(this.props.authExpiration);
    }, 30000);
    this.init();
  }

  init = async () => {
    const token = store.getState().auth.auth.access_token;

    try {
      let defaultCompany;
      let response = await masterServices.consultarCompanias(token);

      if (Array.isArray(response.data) && response.data.length > 0)
        defaultCompany = response.data[0];
      else defaultCompany = {};
      this.setState({
        companies: response.data,
        username: store.getState().auth.auth.name,
        selected_company: defaultCompany.scPersona,
      });
      if (defaultCompany.scPersona && defaultCompany.scPersona !== '') {
        this.props.setCompany(defaultCompany.scPersona);
        this.getNotifications(defaultCompany.scPersona);
        this.props.setPersonType(defaultCompany.tipoPersona);
      }
    } catch {
      this.setState({
        companies: undefined,
        username: store.getState().auth.auth.name,
        selected_company: undefined,
      });
    }
  };

  componentDidUpdate(props, state) {
    const token = store.getState().auth.auth.access_token;
    if (
      this.props.billing.company !== props.billing.company ||
      this.props.billing.reloadNotifications
    ) {
      this.props.loadReportSummaryAction(token, this.props.billing.company);
      this.getNotifications(this.props.billing.company);
      this.props.reloadNotifications(false);
    }
    if (!equals(props.billingSummary, this.props.billingSummary)) {
      let billingResume = this.state.billingResume;
      billingResume[0] = {
        ...billingResume[0],
        value: this.props.billingSummary.totalVrfacturas,
        message: `en ${this.props.billingSummary.totalNmFacturas} Facturas por cobrar`,
      };
      billingResume[1] = {
        ...billingResume[1],
        value: this.props.billingSummary.totalVrNotas,
        message: `en ${this.props.billingSummary.totalNmNotas} Notas crédito`,
      };
      billingResume[2] = {
        ...billingResume[2],
        value: this.props.billingSummary.totalFacturasNuevas,
        message: `facturas nuevas hoy`,
      };
      this.setState({
        ...this.state,
        billingResume: billingResume,
      });
    }

    if (this.props.reachExpiration !== props.reachExpiration) {
      if (this.props.reachExpiration) {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
          setTimeoutCloseSession: setTimeout(this.logout, 110000),
        });
      } else {
        this.setState({
          ...this.state,
          showAuthExpModal: this.props.reachExpiration,
        });
        clearTimeout(this.state.setTimeoutCloseSession);
      }
    }
  }

  updateSelectedCompany = (value, e) => {
    this.setState({
      ...this.state,
      selected_company: value,
    });
    this.props.setCompany(value);
    this.props.setPersonType(e.persontype);
  };

  logout = () => {
    this.props.sessionClose(store.getState().auth.auth.access_token);
  };

  getNotifications = (scProveedor) => {
    const token = store.getState().auth.auth.access_token;
    listarNotificaciones(token, scProveedor).then((response) => {
      var unreadedNotifications = 0;
      if (response.data) {
        response.data.forEach((notification) => {
          if (notification.leido === 'N') {
            unreadedNotifications++;
            notification.dot = 1;
          } else {
            notification.dot = 0;
          }
        });

        this.setState({
          ...this.state,
          notifications: response.data,
          unreadedNotifications,
        });
      }
    });
  };

  updateNotificationStatus(n) {
    if (n.dot > 0) {
      const token = store.getState().auth.auth.access_token;
      const selectedCompany = store.getState().billing.company;
      actualizarEstadoLeido(token, selectedCompany, n.scNotificacion).then(
        (response) => {
          if (response.message) {
            var filteredNotification = this.state.notifications.filter(
              (datum) => datum.scNotificacion !== n.scNotificacion
            );

            var notificationToModify = this.state.notifications.find(
              (datum) => datum.scNotificacion === n.scNotificacion
            );

            filteredNotification.push(notificationToModify);
            notificationToModify.dot = 0;
            this.setState({
              ...this.state,
              notifications: [...filteredNotification],
              unreadedNotifications: this.state.unreadedNotifications - 1,
            });
            notification.success({
              message: response.message,
              duration: 4,
            });
          }
        }
      );
    }
  }

  notificationDrawerVisibility = () => {
    this.setState({
      ...this.state,
      notificationDrawer: !this.state.notificationDrawer,
    });
  };

  render() {
    /**
     * Menu Template
     */
    this.menus = [
      {
        title: 'Inicio',
        icon: HomeOutlined,
        selected: true,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/dashboard`,
        key: 1,
        visible: true,
      },
      {
        title: 'Ver ofertas',
        icon: ShoppingOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/billing`,
        key: 2,
        visible: true,
      },
      {
        //este lleva al paso 2 de billing directamente sin posibilidad de avanzar o volver en el wizard
        title: 'Cobrar todo',
        icon: DollarOutlined,
        selected: true,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/chargeall`,
        key: 3,
        visible: true,
      },
      {
        title: 'Descuento automático',
        icon: RobotOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/agreements`,
        key: 4,
        visible: true,
      },
      {
        title: 'Programar',
        icon: CalendarOutlined,
        selected: true,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/program`,
        key: 5,
        visible: true,
      },
      {
        title: 'Tracking',
        icon: ClockCircleOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/tracking`,
        key: 6,
        visible: true,
      },
      {
        title: 'Solicitudes',
        icon: FormOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/`,
        key: 7,
        visible: true,
        isSubMenu: true,
        children: [
          {
            title: 'Solicita tu cupo',
            icon: null,
            selected: false,
            disabled: false,
            href: `${process.env.PUBLIC_URL}/newrequest/FA`,
            key: 8,
            visible: true,
          },
          {
            title: 'Confirming sin recurso',
            icon: null,
            selected: false,
            disabled: false,
            href: `${process.env.PUBLIC_URL}/newrequest/CSR`,
            key: 11,
            visible: true,
          },
          {
            title: 'Mis solicitudes',
            icon: null,
            selected: false,
            disabled: false,
            href: `${process.env.PUBLIC_URL}/myrequests`,
            key: 12,
            visible: true,
          },
        ],
      },
      {
        title: 'Informes',
        icon: CopyOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/reports/center`,
        key: 13,
        visible: true,
      },
      {
        title: 'Ajustes',
        icon: SettingOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/`,
        key: 14,
        visible: true,
        isSubMenu: true,
        children: [
          {
            title: 'Ficha compañía',
            icon: null,
            selected: false,
            disabled: false,
            href: `${process.env.PUBLIC_URL}/person`,
            key: 15,
            visible: true,
          },
          {
            title: 'Affitoken',
            icon: null,
            selected: false,
            disabled: false,
            href: `${process.env.PUBLIC_URL}/affitoken`,
            key: 16,
            visible: true,
          },
          {
            title: 'Cambiar Contraseña',
            icon: null,
            selected: false,
            disabled: false,
            href: `${process.env.PUBLIC_URL}/changepass`,
            key: 17,
            visible: true,
          },
        ],
      },
      {
        title: 'Vende más facturas',
        icon: CloudUploadOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/bulkload`,
        key: 18,
        visible: true,
      },
      {
        title: 'Estado de cuenta',
        icon: WalletOutlined,
        selected: false,
        disabled: false,
        isSubMenu: true,
        visible: true,
        key: 16,
        children: [
          {
            title: 'Historial de negociación',
            icon: ScheduleOutlined,
            selected: false,
            disabled: false,
            href: `${process.env.PUBLIC_URL}/accountStatus`,
            key: 15,
            visible: true,
          },
          {
            title: 'Reintegros',
            icon: SyncOutlined,
            selected: true,
            disabled: false,
            href: `${process.env.PUBLIC_URL}/refunds`,
            key: 17,
            visible: true,
          },
        ],
      },
      {
        title: 'Políticas de uso',
        icon: WalletOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/tyc`,
        key: 19,
        visible: true,
      },
      {
        title: 'Ayuda',
        icon: QuestionCircleOutlined,
        selected: false,
        disabled: false,
        href: `${process.env.PUBLIC_URL}/helpcenter`,
        key: 20,
        visible: true,
      },
    ];

    return (
      <Layout translate='no' className='notranslate'>
        <Sider className='realasside'>
          <div className='user-container'>
            <Link to={`${process.env.PUBLIC_URL}/dashboard`}>
              <img className='brand-logo' src={logo} alt='' />
            </Link>
            {this.props.children}
            <img className='user-avatar' src={userLogo} alt='' />
            <p className='user-name'>{this.state.username}</p>
            <span>
              <Select
                showSearch
                style={{ width: 200 }}
                placeholder='Seleccione una compañía'
                optionFilterProp='children'
                onChange={this.updateSelectedCompany}
                value={this.state.selected_company}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {this.state.companies !== undefined
                  ? this.state.companies.map((company) => (
                      <Option
                        value={company.scPersona}
                        key={company.scPersona}
                        persontype={company.tipoPersona}
                      >
                        {company.nombre}
                      </Option>
                    ))
                  : ''}
              </Select>
            </span>
          </div>
          <Menu
            className='slider-list'
            mode='inline'
            selectedKeys={[this.state.menuSelectedKey]}
          >
            {this.menus.map((menu, index) =>
              menu.visible ? (
                menu.isSubMenu ? (
                  <SubMenu
                    key={menu.key}
                    title={
                      <span>
                        {menu.icon ? React.createElement(menu.icon) : ''}
                        <span>{menu.title}</span>
                      </span>
                    }
                  >
                    {menu.children.map((submenu) => (
                      <Menu.Item key={submenu.key}>
                        <Link to={submenu.href}>
                          {submenu.icon
                            ? React.createElement(submenu.icon)
                            : ''}
                          {submenu.title}
                        </Link>
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={menu.key}>
                    <Link to={menu.href}>
                      {menu.icon ? React.createElement(menu.icon) : ''}
                      {menu.title + '   '}
                      {menu.key === 14
                        ? React.createElement(
                            'u',
                            { style: { color: '#00ff6a' } },
                            React.createElement(
                              'b',
                              { style: { color: '#00ff6a' } },
                              'NUEVO'
                            )
                          )
                        : ''}
                    </Link>
                  </Menu.Item>
                )
              ) : (
                ''
              )
            )}
          </Menu>
        </Sider>
        <Layout className='content'>
          <Header className='header'>
            <Row className='menu'>
              <Col span={21}>
                {this.fecha.charAt(0).toUpperCase() + this.fecha.slice(1)}
              </Col>
              <Col span={1}>
                <Badge count={this.state.unreadedNotifications}>
                  <BellOutlined
                    className='notification-icon'
                    onClick={this.notificationDrawerVisibility}
                  />
                </Badge>
              </Col>
              <Col span={1}>
                <QuestionCircleOutlined
                  className='notification-icon'
                  onClick={() => {
                    history.push(`${process.env.PUBLIC_URL}/helpcenter`);
                  }}
                />
              </Col>
              <Col span={1} onClick={this.logout} className='logout'>
                {' '}
                <LogoutOutlined className='notification-icon' />
              </Col>
            </Row>
            <Row gutter={16}>
              {this.state.billingResume.map((card, index) => (
                <Col
                  span={card.size}
                  className='custom-card-container'
                  key={index}
                >
                  <div
                    className={`custom-card-body gutter-box card-color${
                      index + 1
                    }`}
                  >
                    <div className='card-icon'>
                      {card.icon ? React.createElement(card.icon) : ''}
                    </div>
                    <div className='card-text'>
                      <span>
                        {index !== 2 ? '$' : ''} {card.value.toLocaleString()}
                      </span>{' '}
                      <br />
                      {card.message}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Header>
          <Content>
            <Switch>
              <Route exact path={`${process.env.PUBLIC_URL}/affitoken`}>
                <Affitoken menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/error`}>
                <Error menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/person`}>
                <Person menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/billing`}>
                <Billing menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/reports/center`}>
                <ReportCenter menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/agreements`}>
                <Agreements menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/helpcenter`}>
                <HelpCenter
                  menuHandler={this.selectedKeyHandler}
                  module='proveedor'
                />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/chargeall`}>
                <ChargeAll menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/program`}>
                <Program menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/tracking`}>
                <Tracking menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/tyc`}>
                <TYC menuHandler={this.selectedKeyHandler} hadAccepted='true' />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/accountStatus`}>
                <AccountStatus menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route exact path={`${process.env.PUBLIC_URL}/refunds`}>
                <Refunds menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/faq`}>
                <FAQ menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/changepass`}>
                <ChangePassword menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/bulkload`}>
                <BulkLoad menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                path={`${process.env.PUBLIC_URL}/newrequest/:tipoSolicitud`}
              >
                <NewRequest menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/myrequests`}>
                <MyRequests menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route
                path={`${process.env.PUBLIC_URL}/oldrequest/:tipoSolicitud/:cdEstado/:scSolicitud`}
              >
                <OldRequest menuHandler={this.selectedKeyHandler} />
              </Route>
              <Route path={`${process.env.PUBLIC_URL}/`}>
                <DashBoard menuHandler={this.selectedKeyHandler} />
              </Route>
            </Switch>
          </Content>
        </Layout>
        <Drawer
          placement='right'
          closable={false}
          width={320}
          onClose={this.notificationDrawerVisibility}
          visible={this.state.notificationDrawer}
        >
          {this.state.notifications.map((notification) => (
            <Card
              className='notification-card'
              key={notification.scNotificacion}
            >
              <Row gutter={16}>
                <Col offset={6} className='title'>
                  {notification.asunto}
                </Col>
              </Row>
              <Row gutter={16}>
                <Col span={6}>
                  <Badge dot count={notification.dot}>
                    <Button
                      className='icon-container'
                      onClick={() =>
                        this.updateNotificationStatus(notification)
                      }
                      size={'large'}
                      shape='circle'
                      icon={<MessageOutlined className='icon' />}
                    />
                  </Badge>
                </Col>
                <Col
                  span={18}
                  className={
                    notification.dot > 0 ? 'description' : 'description-readed'
                  }
                >
                  {notification.mensaje}
                </Col>
              </Row>
            </Card>
          ))}
        </Drawer>
        <Modal
          title='Sessión a punto de finalizar'
          visible={this.state.showAuthExpModal}
          onOk={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
            clearTimeout(this.state.setTimeoutCloseSession);
            this.props.refreshToken(this.props.authRefreshToken);
          }}
          onCancel={() => {
            this.setState({
              ...this.state,
              showAuthExpModal: false,
            });
          }}
        >
          <p>
            Tu sesión esta a punto de finalizar, si deseas continuar operando en
            la plataforma presiona "Aceptar"
          </p>
        </Modal>
      </Layout>
    );
  }
}

ProveedorLayout.propTypes = {
  billing: PropTypes.object.isRequired,
  billingSummary: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  billing: state.billing,
  billingSummary: state.reports.billingSummary,
  authExpiration: state.auth.expiressTimestamp,
  authRefreshToken: state.auth.auth.refresh_token,
  reachExpiration: state.auth.reachExpiration,
});

export default connect(mapStateToProps, {
  setCompany,
  setPersonType,
  initBillSummaryAction,
  loadReportSummaryAction,
  setExpirationTimeout,
  refreshToken,
  resetBillingSteps,
  resetAffitokengSteps,
  reloadNotifications,
  sessionClose,
})(ProveedorLayout);
