import '../../../../styles/standard.scss';
import '../chargeall.scss';
import './assisted.scss';

import { EditOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Divider,
  Modal,
  notification,
  Popover,
  Row,
  Spin,
  Switch,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import React from 'react';
import { connect } from 'react-redux';

import Man from '../../../../assets/img/assisted-man.png';
import CufeModal from '../../../../components/bills/cufeModal/cufeModal';
import history from '../../../../services/history';
import billServices from '../../../../services/proveedor/billServices';
import store from '../../../../store';
import {
  chargeallNextStep,
  chargeallSelectedBills,
} from '../../../../store/actions/proveedor/billingActions';
import {
  BILLING_TIME_ERROR,
  CHARGEALL_TITLE_INIT,
  CHARGEALL_WITH_VALUE_TITLE_INIT,
} from '../../../../utilities/messages';
import { capitalize } from '../../../../utilities/textTools';

const { Text } = Typography;

class Assisted extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ofertas: [],
      loading: false,
      modal: false,
      assistedDetail: {},
      changeDataType: false,
      cufeVisible: false,
      offerCufe: {},
    };
    this.cards = [];
  }

  componentDidMount() {
    this.props.chargeallSelectedBills({
      chargeallSelectedBills: [],
      chargeallTotalSelectedValue: 0,
    });
    this.setState({
      ...this.state,
      loading: true,
    });
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    const { neededAmount } = store.getState().billing;
    if (selectedCompany) {
      billServices
        .listarOfertasCobrarTodo(token, selectedCompany, neededAmount)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              ...this.state,
              ofertas: response.data,
              loading: false,
            });
          } else {
            history.push(`${process.env.PUBLIC_URL}/error`);
          }
        })
        .catch((error) => {
          notification.error({
            message: 'Ha ocurrido un error',
            description: error.response.data.message,
            duration: 4,
          });
        });
    }
  }

  componentDidUpdate(props, state) {
    if (props.billing.company !== this.props.billing.company) {
      this.props.chargeallSelectedBills({
        chargeallSelectedBills: [],
        chargeallTotalSelectedValue: 0,
      });
      this.setState({
        ...this.state,
        loading: true,
        ofertas: [],
      });
      const selectedCompany = this.props.billing.company;
      const token = store.getState().auth.auth.access_token;
      const { neededAmount } = store.getState().billing;
      billServices
        .listarOfertasCobrarTodo(token, selectedCompany, neededAmount)
        .then((response) => {
          if (Array.isArray(response.data) && response.data.length > 0) {
            this.setState({
              ...this.state,
              ofertas: response.data,
              loading: false,
            });
          } else {
            history.push(`${process.env.PUBLIC_URL}/error`);
          }
        })
        .catch((error) => {
          notification.error({
            message: 'Ha ocurrido un error',
            description: error.response.data.message,
            duration: 4,
          });
        });
    }
  }

  assistedDetailBillsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => a.scFactura - b.scFactura,
      showSorterTooltip: false,
    },
    {
      title: 'SOCIEDAD',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => a.nombrePagador - b.nombrePagador,
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmision',
      render: (text) => text,
      sorter: (a, b) => Date.parse(a.feEmision) - Date.parse(b.feEmision),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimiento',
      render: (text) => text,
      sorter: (a, b) =>
        Date.parse(a.feVencimiento) - Date.parse(b.feVencimiento),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFactura',
      render: (value) => '$ ' + this.formatNumber(value),
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      showSorterTooltip: false,
    },
    {
      title: 'OFERTA DE COMPRA',
      dataIndex: 'vrOferta',
      render: (text) => (
        <span className='offer-value'>$ {this.formatNumber(text)}</span>
      ),
      sorter: (a, b) => a.vrOferta - b.vrOferta,
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: 'notasCredito',
      render: (object) =>
        object.length > 0 ? <span className='has-credits'>N </span> : '',
    },
    {
      title: 'CUFE',
      dataIndex: '',
      render: (object) => (
        <Row align='middle'>
          <Col flex='auto'>
            <CufeText cufe={object.cufe} />
          </Col>
          <Col flex='50px'>
            <Tooltip title={object.cufe ? 'Editar CUFE' : 'Agregar CUFE'}>
              <Button
                className='btn-cufe'
                onClick={() => this.openCufeModal(object)}
                icon={<EditOutlined />}
              />
            </Tooltip>
          </Col>
        </Row>
      ),
    },
    {
      title: '',
      dataIndex: 'calculo',
      render: (text) => (
        <Popover
          placement='left'
          content={<div dangerouslySetInnerHTML={{ __html: text }}></div>}
          title='Detalle'
        >
          <InfoCircleTwoTone className='info' />
        </Popover>
      ),
    },
  ];

  assitedDetailCreditsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFacturaRest',
      sorter: (a, b) => a.scFactura - b.scFactura,
      showSorterTooltip: false,
    },
    {
      title: 'SOCIEDAD',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => a.nombrePagador - b.nombrePagador,
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmisionRest',
      render: (text) => text,
      sorter: (a, b) =>
        Date.parse(a.feEmisionRest) - Date.parse(b.feEmisionRest),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimientoRest',
      render: (text) => text,
      sorter: (a, b) =>
        Date.parse(a.feVencimientoRest) - Date.parse(b.feVencimientoRest),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFacturaRest',
      render: (value) => '$ ' + this.formatNumber(value),
      sorter: (a, b) => a.vrFacturaRest - b.vrFacturaRest,
      showSorterTooltip: false,
    },
  ];

  openCufeModal = (oferta) => {
    this.setState({
      ...this.state,
      offerCufe: oferta,
      cufeVisible: true,
    });
  };

  info(investor) {
    var investorCode =
      investor.scFondeador === '10842' ? '20132' : investor.scFondeador;
    Modal.info({
      title: 'Condiciones de ' + investor.nombreFondeador,
      width: 900,
      content: (
        <iframe
          id='conditions'
          title='Condiciones'
          width='100%'
          height='600px'
          src={
            'https://www.exponencialconfirming.com/condiciones-de-uso-' +
            investorCode
          }
        ></iframe>
      ),
      onOk() {},
    });
  }


  async updateModalVisibility(boolean, fondeador, scGrupo) {
    if (boolean) {
      if (scGrupo) {
        this.setState({
          ...this.state,
          loading: true,
        });
        const { neededAmount } = store.getState().billing;
        const selectedCompany = store.getState().billing.company;
        const token = store.getState().auth.auth.access_token;
        let bills = await billServices
          .verFacturasResumen(
            token,
            selectedCompany,
            fondeador.scFondeador,
            scGrupo,
            neededAmount
          )
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            notification.error({
              message: 'Error',
              description: error.response.data.message,
              duration: 5,
            });
          });

        let credits = await billServices
          .verNotasResumen(
            token,
            selectedCompany,
            fondeador.scFondeador,
            scGrupo,
            neededAmount
          )
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            notification.error({
              message: 'Error',
              description: error.response.data.message,
              duration: 5,
            });
          });
        this.setState({
          ...this.state,
          assistedDetail: {
            bills: bills,
            credits: credits,
          },
          loading: false,
          modal: true,
        });
      }
    } else {
      this.setState({
        ...this.state,
        changeDataType: false,
        modal: boolean,
      });
    }
  }

  nextStep = () => {
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    var bills = store.getState().billing.chargeallSelectedBills;
    var facturasProcesadas = [];

    const { assistedDetail } = this.state;

    bills = Object.values(bills);
    bills.forEach((element) => {
      element.forEach((datum) => {
        facturasProcesadas.push(datum);
      });
    });

    const scFacturasSelected = bills
      .flat()
      .filter((el) => !el.cufe || el.cufe === '' || el.cufe === null)
      .map((el) => el.scFactura);

    const billsCufe =
      assistedDetail && assistedDetail.bills
        ? assistedDetail.bills
            .filter(
              (bill) =>
                scFacturasSelected.filter((sc) => bill.scFactura === sc)
                  .length > 0
            )
            .filter((el) => !el.cufe || el.cufe === '' || el.cufe === null)
        : scFacturasSelected;

    if (billsCufe.length > 0) {
      notification.error({
        message: 'CUFE',
        description: `Una o mas facturas no tiene el código CUFE válido o registrado`,
        duration: 4,
      });
      return;
    }

    this.setState({
      ...this.state,
      loading: true,
    });

    billServices
      .confirmarCompraOferta(token, selectedCompany, facturasProcesadas)
      .then((response) => {
        this.setState({
          ...this.state,
          loading: false,
        });
        this.props.chargeallNextStep(1);
        this.props.stepHandler();
      })
      .catch(() => {
        this.setState({
          ...this.state,
          loading: false,
        });
      });
  };

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  obtenerCampo = (campo, oferta) => {
    const fondeador = oferta.resumenOferta.find(
      (resumen) => resumen.selected === true
    );

    return fondeador ? fondeador[campo] : '';
  };

  seleccionarFondeador = (offer, fondeador) => {
    if (fondeador.aprobado) {
      var totalSeleccionado =
        store.getState().billing.chargeallTotalSelectedValue;
      const ofertaIndex = this.state.ofertas.findIndex(
        (of) =>
          offer.grupoEmpresariales.scGrupo === of.grupoEmpresariales.scGrupo
      );

      const fondeadorAnteriorIndex = this.state.ofertas[
        ofertaIndex
      ].resumenOferta.findIndex((summary) => summary.selected === true);

      if (fondeadorAnteriorIndex >= 0) {
        totalSeleccionado -=
          this.state.ofertas[ofertaIndex].resumenOferta[fondeadorAnteriorIndex]
            .vrOfertaTotal;
      }

      const fondeadorIndex = this.state.ofertas[
        ofertaIndex
      ].resumenOferta.findIndex(
        (summary) => fondeador.scFondeador === summary.scFondeador
      );

      this.state.ofertas[ofertaIndex].resumenOferta.forEach((element) => {
        element['selected'] = false;
      });

      this.state.ofertas[ofertaIndex].resumenOferta[fondeadorIndex][
        'selected'
      ] = true;
      totalSeleccionado +=
        this.state.ofertas[ofertaIndex].resumenOferta[fondeadorIndex]
          .vrOfertaTotal;

      this.setState({
        ...this.state,
      });

      this.gestionarFacturas(
        this.state.ofertas[ofertaIndex].resumenOferta[fondeadorIndex],
        this.state.ofertas[ofertaIndex].grupoEmpresariales.scGrupo,
        totalSeleccionado
      );
    }
  };

  changeTable(boolean) {
    this.setState({
      ...this.state,
      changeDataType: !boolean,
    });
  }

  operacionCompleta = () => {
    var finalizado = false;
    for (var i = 0; i < this.state.ofertas.length; i++) {
      for (var j = 0; j < this.state.ofertas[i].resumenOferta.length; j++) {
        if (!this.state.ofertas[i].resumenOferta[j].selected) {
          finalizado = false;
        } else {
          finalizado = true;
          break;
        }
      }
      if (!finalizado) {
        break;
      }
    }
    return finalizado;
  };

  gestionarFacturas = (fondeador, scGrupo, chargeallTotalSelectedValue) => {
    const { neededAmount } = store.getState().billing;
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;
    billServices
      .verFacturasResumen(
        token,
        selectedCompany,
        fondeador.scFondeador,
        scGrupo,
        neededAmount
      )
      .then((response) => {
        var facturas = [];
        response.data.forEach((factura) => {
          var utc = new Date().toJSON().slice(0, 10);
          const newBill = {
            feIngreso: utc,
            scFactura: factura.scFactura,
            scFondeador: fondeador.scFondeador,
            scProveedor: selectedCompany,
            scpersonaModifica: '',
            vrOferta: factura.vrOferta,
            scNombreFondeador: fondeador.nombreFondeador,
            tipo: 'O',
            cufe: factura.cufe,
          };
          facturas.push(newBill);
        });
        var chargeallSelectedBills =
          store.getState().billing.chargeallSelectedBills;
        chargeallSelectedBills['scGrupo-' + scGrupo] = facturas;
        this.props.chargeallSelectedBills({
          chargeallSelectedBills,
          chargeallTotalSelectedValue,
        });
      });
  };

  handleCufeOnClose = (offer) => {
    if (offer) {
      const { assistedDetail } = this.state;

      const aux = assistedDetail.bills.map((bill) => {
        if (bill.scFactura === offer.scFactura) {
          return { ...bill, cufe: offer.cufe };
        } else {
          return bill;
        }
      });

      this.setState({
        ...this.state,
        cufeVisible: false,
        offerCufe: {},
        assistedDetail: { ...assistedDetail, bills: [...aux] },
      });
    } else {
      this.setState({
        ...this.state,
        cufeVisible: false,
        offerCufe: {},
      });
    }
  };

  render() {
    const { neededAmount } = store.getState().billing;
    const selectedCompany = store.getState().billing.company;
    const token = store.getState().auth.auth.access_token;

    return (
      <div className='assisted-style'>
        <Spin tip='Cargando...' spinning={this.state.loading}>
          <Card className='card-shadow card-radius default-border first-message'>
            <Row>
              <Col span={7} offset={2}>
                <img src={Man} alt='' />
              </Col>
              <Col span={11} offset={1}>
                <div className='title'>
                  ¡Hola{' '}
                  {capitalize(
                    store.getState().auth.auth.name.split(' ')[0].toLowerCase()
                  ).trim()}
                  !
                </div>
                <div className='description'>
                  {neededAmount > 0
                    ? CHARGEALL_WITH_VALUE_TITLE_INIT
                    : CHARGEALL_TITLE_INIT}
                </div>
              </Col>
            </Row>
          </Card>
          {neededAmount > 0 ? (
            <Row className='title-container'>
              <Col span={24}>
                <div className='title'>Dinero que necesitas hoy : </div>
                <div className='value'>
                  {' '}
                  $ {this.formatNumber(neededAmount)}
                </div>
              </Col>
            </Row>
          ) : (
            ''
          )}
          {this.state.ofertas ? (
            <div>
              {this.state.ofertas.map((offer) => (
                <Card
                  className='client-container card-radius card-shadow default-border'
                  key={offer.grupoEmpresariales.scGrupo}
                >
                  <Row>
                    <Col className='client'>
                      {offer.grupoEmpresariales.dsNombres}
                    </Col>
                  </Row>
                  <Divider />
                  <Row
                    gutter={[16, 16]}
                    className='investors-container'
                    align='middle'
                  >
                    {offer.resumenOferta.map((summary, key) => {
                      return (
                        <Col
                          xl={{ span: 6 }}
                          lg={{ span: 8 }}
                          md={{ span: 12 }}
                          key={summary.scFondeador}
                        >
                          <Card
                            ref={(cards) => (this.cards[key] = cards)}
                            onClick={() =>
                              this.seleccionarFondeador(offer, summary)
                            }
                            className={
                              'investor-card card-radius default-border card-shadow ' +
                              (summary.selected ? ' active selected' : '')
                            }
                          >
                            <div className='title'>
                              {summary.nombreFondeador}
                            </div>
                            <div className={'logo-container'}>
                              <img
                                src={
                                  summary.dsLogo
                                    ? summary.dsLogo
                                    : 'https://resources-affirmatum.s3.amazonaws.com/Pronto_Pago_MF.png'
                                }
                                alt=''
                                className='logo'
                              />
                            </div>
                            <div className='value'>
                              $ {this.formatNumber(summary.vrOfertaTotal)}
                            </div>
                            <div className='bill-amount'>
                              Nro.Facturas: {summary.nroFacturas}
                            </div>
                            <div className='rate'>
                              TASA:{' '}
                              {summary.grupoEmpresariales.map((el) =>
                                String(el.tasa).substring(0, 4)
                              )}
                            </div>
                            <div className='conditions'>
                              <a href='#' onClick={() => this.info(summary)}>
                                VER CONDICIONES
                              </a>
                            </div>
                            <Row className='detail-container'>
                              <Col span={24}>
                                <Button
                                  onClick={() =>
                                    this.updateModalVisibility(
                                      true,
                                      summary,
                                      offer.grupoEmpresariales.scGrupo
                                    )
                                  }
                                >
                                  VER DETALLE
                                </Button>
                              </Col>
                            </Row>
                            {!summary.aprobado && (
                              <div className='disabled-investor'>
                                <p>{BILLING_TIME_ERROR}</p>
                              </div>
                            )}
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                </Card>
              ))}

              {!this.state.loading && (
                <Row className='btn-next-container'>
                  <Col span={24} className='align-center'>
                    <Button
                      disabled={!this.operacionCompleta()}
                      className={`btn-next ${
                        !this.operacionCompleta() ? 'disabled' : ''
                      }`}
                      onClick={this.nextStep}
                    >
                      Siguiente
                    </Button>
                  </Col>
                </Row>
              )}
            </div>
          ) : (
            ''
          )}
          <Modal
            className='assisted-modal'
            centered
            title={'Resumen facturas'}
            destroyOnClose={true}
            visible={this.state.modal}
            onOk={() => this.updateModalVisibility(false, 0)}
            onCancel={() => this.updateModalVisibility(false, 0)}
            footer={null}
            width={768}
          >
            <Row gutter={16}>
              <Col xl={{ span: 5 }} md={{ span: 6 }} className='select-label'>
                Facturas/Notas crédito
              </Col>
              <Col
                xl={{ span: 5 }}
                md={{ span: 8 }}
                className='switch-container'
              >
                <Switch
                  checkedChildren='Notas crédito'
                  checked={this.state.changeDataType}
                  unCheckedChildren='Facturas'
                  onChange={() => this.changeTable(this.state.changeDataType)}
                />
              </Col>
            </Row>
            <Row gutter={16} className='margin-top'>
              <Col span={24}>
                <Table
                  loading={this.state.loading}
                  pagination={{
                    defaultPageSize: 10,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '30'],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} de ${total} registros`,
                  }}
                  columns={
                    this.state.changeDataType
                      ? this.assitedDetailCreditsColumns
                      : this.assistedDetailBillsColumns
                  }
                  dataSource={
                    this.state.changeDataType
                      ? this.state.assistedDetail.credits
                      : this.state.assistedDetail.bills
                  }
                ></Table>
              </Col>
            </Row>
          </Modal>
        </Spin>
        <CufeModal
          oferta={this.state.offerCufe}
          visible={this.state.cufeVisible}
          onClose={this.handleCufeOnClose}
          token={token}
          company={selectedCompany}
        />
      </div>
    );
  }
}

const CufeText = ({ cufe }) => {
  return (
    <Tooltip title={cufe ? cufe : ''} color={'#09d96d'}>
      <Text
        style={{
          maxWidth: '90px',
        }}
        ellipsis={{
          suffix: cufe ? '.' : '...',
        }}
      >
        {cufe ? cufe : ''}
      </Text>
    </Tooltip>
  );
};

const mapStateToProps = (state) => ({
  billing: state.billing,
});

export default connect(mapStateToProps, {
  chargeallNextStep,
  chargeallSelectedBills,
})(Assisted);
