import { Button, Card, Col, Spin, Tabs, notification } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { listarMonedas } from '../../../../../services/admin/monedasService';
import {
  crearConvenio,
  listarTipoFe,
  listarTiposCobros,
  listarTiposConvenios,
} from '../../../../../services/admin/operation';
import { listarPagadores } from '../../../../../services/admin/reports/quotasService';
import history from '../../../../../services/history';
import { initialSupplierValuesForm } from '../../../clients/suppliers/BasicDataForm/BasicDataForm';
import '../agreementOperation.scss';
import BasicDataForm from '../basicDataForm/basicDataForm';

const AGREEMENTS_TITLE = 'Proveedor';

const initialState = {
  loading: false,
  pagadorSelect: {
    options: [],
    fetching: false,
  },
  data: {
    ...initialSupplierValuesForm,
  },
  tipoConvenios: [],
  tipoCobros: [],
  monedas: [],
  tiposFe: [],
};

class AgreementNewForm extends Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState };
  }

  redirectToAgreements = () => {
    history.push(`${process.env.PUBLIC_URL}/operation`);
  };

  redirectToEditAgreements = (scConvenio) => {
    history.push(
      `${process.env.PUBLIC_URL}/operation/edit/${btoa(scConvenio)}`
    );
  };

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnFinish = async (formValue) => {
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const response = await crearConvenio(this.props.authToken, formValue);

      notification.success({
        message: AGREEMENTS_TITLE,
        description: response.message,
        duration: 6,
      });
      this.redirectToEditAgreements(response.location);
    } finally {
      this.setState({
        ...this.state,
        loading: false,
      });
    }
  };

  getData = async () => {
    const { authToken } = this.props;
    const { pagadorSelect } = this.state;
    this.setState({
      ...this.state,
      loading: true,
    });

    try {
      const tipoConvenioRes = await listarTiposConvenios(authToken);
      const tipoCobroRes = await listarTiposCobros(authToken);
      const monedas = await listarMonedas(authToken, { limit: 300 });
      const tipoFeRes = await listarTipoFe(authToken, { limit: 300 });

      const response = await listarPagadores(this.props.authToken, {
        nombre: '',
      });

      this.setState({
        ...this.state,
        loading: false,
        tipoConvenios: tipoConvenioRes.data,
        tipoCobros: tipoCobroRes.data,
        monedas: monedas.data,
        tiposFe: tipoFeRes.data,
        pagadorSelect: {
          ...pagadorSelect,
          options: response.data,
          fetching: false,
        },
      });
    } catch {
      this.redirectToAgreements();
    }
  };

  componentDidMount() {
    this.props.menuHandler('41');
    this.getData();
  }

  render() {
    const {
      loading,
      pagadorSelect,
      tipoConvenios,
      tipoCobros,
      monedas,
      tiposFe,
    } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='suppliers-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Tabs activeKey={'1'}>
              <Tabs.TabPane key={'1'} tab='Convenios'>
                <BasicDataForm
                  tipoConvenios={tipoConvenios}
                  tipoCobros={tipoCobros}
                  monedas={monedas}
                  pagadorSelect={pagadorSelect}
                  tiposFe={tiposFe}
                  handleOnSearchPagador={this.handleOnSearchPagador}
                  onFinish={this.handleOnFinish}
                >
                  <>
                    <Col span={6}>
                      <Button
                        style={{ width: '90%' }}
                        className='btn btn-blue'
                        type='primary'
                        onClick={this.redirectToAgreements}
                      >
                        Lista de Convenios
                      </Button>
                    </Col>
                  </>
                </BasicDataForm>
              </Tabs.TabPane>
            </Tabs>
          </Card>
        </div>
      </Spin>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(AgreementNewForm);
