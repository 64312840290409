import { Button, Col, Form, Row, Select, Spin } from 'antd';
import React from 'react';
import { requiredValueMessage } from '../../../../../utilities/admin_validation_messages';

export const initialFormValue = {
  cdTipoEventoDE: undefined,
  scSenderParty: undefined,
  scReceiverParty: undefined,
};

const rules = {
  cdTipoEventoDE: [{ required: true, message: requiredValueMessage() }],
  scSenderParty: [{ required: true, message: requiredValueMessage() }],
  scReceiverParty: [{ required: true, message: requiredValueMessage() }],
};

const initialState = {
  cdTipoEvento: '',
};

class EventsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.form = React.createRef();
  }

  hanldeOnChangeTipoEvento = (e) => {
    const { onChangeTipoEvento } = this.props;
    this.setState({
      ...this.state,
      cdTipoEvento: e,
    });

    onChangeTipoEvento(e);
  };

  componentDidMount() {
    this.form.current.setFieldsValue({ ...initialFormValue });
  }

  componentDidUpdate(props, state) {
    if (JSON.stringify(this.props.data) !== JSON.stringify(props.data)) {
      const { scReceiverParty, scSenderParty } = this.props.data;
      this.form.current.setFieldsValue({
        scReceiverParty,
        scSenderParty,
      });
    }
  }

  render() {
    const {
      tiposEventos,
      onSenderPartySearch,
      senderPartySelect,
      receiverPartySelect,
      onReceiverPartySearch,
      onFinish,
    } = this.props;

    const { cdTipoEvento } = this.state;

    return (
      <div className='header-container'>
        <Form layout='vertical' ref={this.form} onFinish={onFinish}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name='cdTipoEventoDE'
                label='Tipo de Eventos'
                rules={rules['cdTipoEventoDE'] || ''}
              >
                <Select
                  onChange={this.hanldeOnChangeTipoEvento}
                  placeholder='Seleccione un tipo de evento'
                >
                  {tiposEventos.map((d) => (
                    <Select.Option key={d.codigo} value={d.codigo}>
                      {d.descripcion}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          {(cdTipoEvento === 'AT' ||
            cdTipoEvento === 'FETV' ||
            cdTipoEvento === 'EPP' ||
            cdTipoEvento === 'IP' ||
            cdTipoEvento === 'PG' ||
            cdTipoEvento === 'FETVP') && (
            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item
                  name='scSenderParty'
                  label='SENDER PARTY'
                  rules={rules['scSenderParty'] || ''}
                >
                  <Select
                    className='module-selector-select'
                    placeholder='Seleccione'
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={onSenderPartySearch}
                    notFoundContent={
                      senderPartySelect.fetching ? <Spin size='small' /> : null
                    }
                  >
                    {senderPartySelect.options.map((value, i) => (
                      <Select.Option key={i} value={value.codigo}>
                        {value.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}

          {(cdTipoEvento === 'EPP' || cdTipoEvento === 'IP') && (
            <Row gutter={16}>
              <Col flex='auto'>
                <Form.Item
                  name='scReceiverParty'
                  label='RECEIVER PARTY'
                  rules={rules['scReceiverParty'] || ''}
                >
                  <Select
                    className='module-selector-select'
                    placeholder='Seleccione'
                    showSearch
                    allowClear
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onSearch={onReceiverPartySearch}
                    notFoundContent={
                      receiverPartySelect.fetching ? (
                        <Spin size='small' />
                      ) : null
                    }
                  >
                    {receiverPartySelect.options.map((value, i) => (
                      <Select.Option key={i} value={value.codigo}>
                        {value.descripcion}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          )}
          <Row justify='center'>
            <Col span={8}>
              <Form.Item>
                <Button
                  className='form-btn btn-radius'
                  type='primary'
                  htmlType='submit'
                >
                  Grabar
                </Button>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default EventsForm;
