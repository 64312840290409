import axios from 'axios';
import { middleWare } from '../middleware';
import queryString from 'query-string';

const baseUrl = process.env.REACT_APP_SERVICES_URL_ADMIN;

/* #region Obtener Datos */

export const listarFacturasAnuladas = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/facturas-anuladas?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasAnuladasExcel = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/facturas-anuladas/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasCargadas = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/facturas-cargadas?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasCargadasF4All = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/facturas-cargadas-f4all?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasCargadasExcel = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/facturas-cargadas/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasCargadasExcelF4All = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/facturas/facturas-cargadas-f4all/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasProgramadas = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/ofertas/ofertas-programadas?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};
export const listarFacturasProgramadasExcel = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/ofertas/ofertas-programadas/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasDescAuto = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/ofertas/ofertas-DescAuto?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const listarFacturasDescAutoExcel = (authtoken, filtros) => {
  let filters = formatoFechas(filtros);
  return middleWare(
    axios({
      url: `${baseUrl}/api/ofertas/ofertas-DescAuto/exportar/xlsx?${queryString.stringify(
        filters
      )}`,
      method: 'GET',
      responseType: 'blob',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${authtoken}`,
      },
      data: null,
    })
  );
};

export const executeTask = (
  authtoken,
  task,
  keys,
  selectAll,
  justificacion,
  filtros
) => {
  if (selectAll) {
    let filters = formatoFechas(filtros);
    return middleWare(
      axios({
        url: `${baseUrl}/api/facturas/facturas-cargadas/ejecutar/all`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authtoken}`,
        },
        data: {
          ...filters,
          tarea: task,
          scFacturas: keys,
          justificacion: justificacion,
        },
      })
    );
  } else {
    return middleWare(
      axios({
        url: `${baseUrl}/api/facturas/facturas-cargadas/ejecutar`,
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authtoken}`,
        },
        data: {
          justificacion: justificacion,
          tarea: task,
          scFacturas: keys,
        },
      })
    );
  }
};

/* #endregion */

const formatoFechas = (filtros) => {
  let empty = {};
  Object.keys(filtros).forEach((nombre) => {
    if (nombre !== 'resultado') {
      if (
        (nombre.includes('Desde') || nombre.includes('Hasta')) &&
        typeof filtros[nombre] === 'object'
      ) {
        empty[nombre] = filtros[nombre].format('YYYYMMDD');
      } else if (
        filtros[nombre] &&
        nombre != 'currentPage' &&
        nombre != 'total'
      ) {
        empty[nombre] = filtros[nombre];
      }
    }
  });
  return empty;
};
