import React from 'react';
import './bills.scss';
import {
  Row,
  Col,
  Card,
  Select,
  Switch,
  Divider,
  Table,
  Modal,
  Popover,
  Spin,
} from 'antd';
import {
  ShoppingCartOutlined,
  CaretDownOutlined,
  InfoCircleTwoTone,
  FileExcelOutlined,
} from '@ant-design/icons';
import { connect } from 'react-redux';
import * as billServices from '../../../../../services/pagador/billServices';

import { Button } from 'antd';
import { Redirect } from 'react-router-dom';
import {
  BILLING_TITLE_INIT,
  BILLING_SUBTITLE_INIT,
  BILLING_TIME_ERROR,
} from '../../../../../utilities/messages';
import { withRouter } from 'react-router';
import history from '../../../../../services/history';
import CondicionesModal from '../../../../../components/bills/condicionesModal/condicionesModal';

const { Option } = Select;

class Bill extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      investors: [],
      selectedInvestor: '',
      selectedBusinessGroup: '',
      listedBills: [],
      redirect: false,
      loading: false,
      scProveedor: '',
      scPagador: '',
      condicionVisible: false,
      condicionDataFondeador: {},
    };

    this.businessGroupSelect = React.createRef();
    this.cards = [];
  }

  billsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'cdFactura',
      sorter: (a, b) => a.cdFactura - b.cdFactura,
      showSorterTooltip: false,
    },
    {
      title: 'SOCIEDAD',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => a.nombrePagador - b.nombrePagador,
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmision',
      render: (text) => this.formatDate(text),
      sorter: (a, b) => Date.parse(a.feEmision) - Date.parse(b.feEmision),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimiento',
      render: (text) => this.formatDate(text),
      sorter: (a, b) =>
        Date.parse(a.feVencimiento) - Date.parse(b.feVencimiento),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFactura',
      render: (value) => '$ ' + this.formatNumber(value),
      sorter: (a, b) => a.vrFactura - b.vrFactura,
      showSorterTooltip: false,
    },
    {
      title: 'OFERTA DE COMPRA',
      dataIndex: 'vrOferta',
      render: (text) => (
        <span className='offer-value'>$ {this.formatNumber(text)}</span>
      ),
      sorter: (a, b) => a.vrOferta - b.vrOferta,
      showSorterTooltip: false,
    },
    // {
    //     title: '',
    //     dataIndex: 'action',
    //     render: () => <DatePicker placeholder="Seleccione una fecha" suffixIcon={<CalendarOutlined className="calendar" />} />
    // },
    {
      title: '',
      dataIndex: 'notasCredito',
      render: (object) =>
        object.length > 0 ? <span className='has-credits'>N </span> : '',
      sorter: (a, b) => {
        if (a.notasCredito.length > 0 && b.notasCredito.length <= 0) {
          return -1;
        }
        if (a.notasCredito.length <= 0 && b.notasCredito.length > 0) {
          return 1;
        }

        if (a.notasCredito.length > 0 && b.notasCredito.length > 0) {
          return 0;
        }
      },
      showSorterTooltip: false,
    },
    {
      title: '',
      dataIndex: 'inCart',
      render: (text) =>
        text && (
          <span className='current-founder'>
            {text} <ShoppingCartOutlined />
          </span>
        ),
    },
    {
      title: '',
      dataIndex: 'detail',
      render: (text) => (
        <Popover placement='topRight' content={text} title='Detalle'>
          <InfoCircleTwoTone className='info' />
        </Popover>
      ),
    },
  ];

  creditsColumns = [
    {
      title: '# FACTURA',
      dataIndex: 'scFactura',
      sorter: (a, b) => a.scFactura - b.scFactura,
      showSorterTooltip: false,
    },
    {
      title: 'SOCIEDAD',
      dataIndex: 'nombrePagador',
      sorter: (a, b) => a.nombrePagador - b.nombrePagador,
      showSorterTooltip: false,
    },
    {
      title: 'EMISIÓN',
      dataIndex: 'feEmisionRest',
      render: (text) => this.formatCreditDate(text),
      sorter: (a, b) =>
        Date.parse(this.formatCreditDate(a.feEmisionRest)) -
        Date.parse(this.formatCreditDate(b.feEmisionRest)),
      showSorterTooltip: false,
    },
    {
      title: 'VENCIMIENTO',
      dataIndex: 'feVencimientoRest',
      render: (text) => this.formatCreditDate(text),
      sorter: (a, b) =>
        Date.parse(this.formatCreditDate(a.feVencimientoRest)) -
        Date.parse(this.formatCreditDate(b.feVencimientoRest)),
      showSorterTooltip: false,
    },
    {
      title: 'VALOR PENDIENTE',
      dataIndex: 'vrFacturaRest',
      render: (value) => '$ ' + this.formatNumber(value),
      sorter: (a, b) => a.vrFacturaRest - b.vrFacturaRest,
      showSorterTooltip: false,
    },
  ];

  componentDidUpdate(props, state) {
    if (
      state.selectedInvestor.scFondeador !==
      this.state.selectedInvestor.scFondeador
    ) {
      this.businessGroupSelection('all', this.state.selectedInvestor);
    }
  }

  componentDidMount() {
    const selectedCompany = atob(this.props.match.params.scPagador);
    const selectedProvider = atob(this.props.match.params.scProveedor);
    if (selectedCompany !== '') {
      this.setState({
        ...this.state,
        loading: true,
        scProveedor: selectedProvider,
        scPagador: selectedCompany,
      });
      const token = this.props.authToken;
      billServices
        .listarFondeadores(token, selectedCompany, selectedProvider)
        .then((response) => {
          if (response.data && response.data.length > 0) {
            var selectedInvestor = '';
            this.setState({
              ...this.state,
              investors: response.data,
              selectedInvestor: selectedInvestor || '',
              loading: false,
            });
          } else {
            this.setState({
              ...this.state,
              redirect: true,
              loading: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            ...this.state,
            redirect: true,
            loading: false,
          });
        });
    }
  }
  
  handleOpenCondicionModal =(investor) => {
    this.state.condicionVisible = true;
    let dataFondeador = {
      nombre: investor.nombreFondeador,
      scFondeador: investor.scFondeador,
      mensajeFondeador: investor.mensajeFondeadorCondicion,
    };
    this.state.condicionDataFondeador = dataFondeador;
    this.setState({
      ...this.state,
      condicionVisible: true,
      condicionDataFondeador: dataFondeador,
    });
  };
  handleCondicionOnClose = () => {
    this.setState({
      ...this.state,
      condicionVisible: false,
    });
  };

  formatNumber(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
  }

  formatDate(date) {
    var arrDate = date.split('T');
    if (arrDate.length > 0) {
      return arrDate[0];
    } else {
      return date;
    }
  }

  formatCreditDate(date) {
    var newDate = new Date(date);
    var year = newDate.getFullYear();
    var month = newDate.getMonth() + 1;
    var day = newDate.getDate();
    month = month < 9 ? '0' + month.toString() : month;
    day = day < 9 ? '0' + day.toString() : day;
    newDate = (year + '-' + month + '-' + day).toString();
    return newDate;
  }

  formatInvestorDate = (date) => {
    var newDate = {};
    newDate.fullDate = new Date(date);
    newDate.day = newDate.fullDate.getDay();
    newDate.month = newDate.fullDate.getMonth();
    newDate.dayNumber = newDate.fullDate.getDate();
    var currentDate = {};
    currentDate.fullDate = new Date();
    currentDate.day = currentDate.fullDate.getDay();
    currentDate.month = currentDate.fullDate.getMonth();
    currentDate.dayNumber = currentDate.fullDate.getDate();
    if (
      newDate.day === currentDate.day &&
      newDate.month === currentDate.month &&
      newDate.dayNumber === currentDate.dayNumber
    ) {
      return 'Hoy';
    } else {
      const months = [
        'enero',
        'febrero',
        'marzo',
        'abril',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre',
      ];
      const days = [
        'Domingo',
        'Lunes',
        'Martes',
        'Miercoles',
        'Jueves',
        'Viernes',
        'Sabado',
      ];
      return (
        days[newDate.day] +
        ' ' +
        newDate.dayNumber +
        ' de ' +
        months[newDate.month]
      );
    }
  };

  investorSelection(e) {
    if (e.aprobado) {
      this.setState({
        ...this.state,
        selectedInvestor: e,
        selectedBusinessGroup: {
          scGrupo: 'all',
        },
        selectedRows: [],
        loading: true,
      });
    }
    this.billsSelectionSectioIdTime = setTimeout(() => {
      this.setState({
        ...this.state,
        loading: false,
      });
      var section = document.getElementById('bill-selection-section');
      if (section) {
        section.scrollIntoView();
      }
    }, 1000);
  }

  creditReducer = (accumulator, currentValue) =>
    accumulator + currentValue.vrFacturaRest;

  offerReducer = (accumulator, currentValue) =>
    accumulator + currentValue.vrOferta;

  businessGroupSelection = (value, e) => {
    var selectedInvestor = '';
    selectedInvestor = this.state.selectedInvestor;
    const selectedCompany = this.state.scPagador;
    const token = this.props.authToken;

    this.setState({
      ...this.state,
      listedBills: [],
      loading: true,
      selectedBusinessGroup: {
        scGrupo: 'all',
      },
    });
    if (value !== 'all') {
      billServices
        .listarOferta(
          token,
          selectedCompany,
          this.state.scProveedor,
          selectedInvestor.scFondeador,
          value
        )
        .then((response) => {
          var objBusinessGroup = {};
          objBusinessGroup.totalOffer = 0;
          objBusinessGroup.totalCredit = 0;
          objBusinessGroup.checked = false;
          objBusinessGroup.collapsed = 'collapsed';
          objBusinessGroup.bills = response.data;
          objBusinessGroup.credits = [];
          objBusinessGroup.info = selectedInvestor.grupoEmpresariales
            .map(
              (grupoEmpresarial) =>
                grupoEmpresarial.scGrupo === value && grupoEmpresarial
            )
            .filter(Boolean)[0];
          for (var i = 0; i < response.data.length; i++) {
            objBusinessGroup.bills[i].key =
              objBusinessGroup.bills[i].scFactura +
              '-' +
              value +
              '-' +
              selectedInvestor.scFondeador;
            objBusinessGroup.bills[i].detail = (
              <div
                dangerouslySetInnerHTML={{
                  __html: objBusinessGroup.bills[i].calculo,
                }}
              ></div>
            );
            objBusinessGroup.totalOffer += objBusinessGroup.bills[i].vrOferta;
            if (objBusinessGroup.bills[i].notasCredito.length > 0) {
              objBusinessGroup.bills[i].notasCredito.forEach((credit) => {
                objBusinessGroup.totalCredit += credit.vrFacturaRest;
                credit.key =
                  credit.scFactura +
                  '-' +
                  value +
                  '-' +
                  selectedInvestor.scFondeador;
                objBusinessGroup.credits.push(credit);
              });
              objBusinessGroup.totalCredit += objBusinessGroup.bills[
                i
              ].notasCredito.reduce(this.creditReducer, 0);
            }
          }
          this.setState({
            ...this.state,
            selectedBusinessGroup: objBusinessGroup.info,
            listedBills: [objBusinessGroup],
            loading: false,
          });
        })
        .catch((error) => {
          this.setState({
            ...this.state,
            redirect: true,
            loading: false,
          });
        });
    } else {
      const objInvestor = selectedInvestor ? selectedInvestor : e;
      var selectedBills = [];
      if (objInvestor) {
        objInvestor.grupoEmpresariales.forEach((grupoEmpresarial) => {
          billServices
            .listarOferta(
              token,
              selectedCompany,
              this.state.scProveedor,
              objInvestor.scFondeador,
              grupoEmpresarial.scGrupo
            )
            .then((response) => {
              selectedBills = [];
              selectedBills.info = grupoEmpresarial;
              selectedBills.checked = false;
              selectedBills.totalOffer = response.data.reduce(
                this.offerReducer,
                0
              );
              selectedBills.totalCredit = 0;
              selectedBills.collapsed = 'collapsed';
              selectedBills.credits = [];
              response.data.forEach((bill) => {
                bill.key =
                  bill.scFactura +
                  '-' +
                  grupoEmpresarial.scGrupo +
                  '-' +
                  objInvestor.scFondeador;
                bill.detail = (
                  <div dangerouslySetInnerHTML={{ __html: bill.calculo }}></div>
                );
                if (bill.notasCredito.length > 0) {
                  bill.notasCredito.forEach((credit) => {
                    selectedBills.totalCredit += credit.vrFacturaRest;
                    credit.key =
                      credit.scFactura +
                      '-' +
                      grupoEmpresarial.scGrupo +
                      '-' +
                      objInvestor.scFondeador;
                    selectedBills.credits.push(credit);
                  });
                }
              });
              selectedBills.bills = response.data;
              this.setState({
                ...this.state,
                listedBills: [...this.state.listedBills, selectedBills],
                loading: false,
              });
            })
            .catch((error) => {
              this.setState({
                ...this.state,
                redirect: true,
                loading: false,
              });
            });
        });
      } else {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  changeTable = (businessGroup) => {
    var module = '';
    module = 'listedBills';
    var index = this.state[module].findIndex(
      (listedBill) => businessGroup.info.scGrupo === listedBill.info.scGrupo
    );
    this.state[module][index].checked = !this.state[module][index].checked;
    this.setState({
      ...this.state,
    });
  };

  openTable = (businessGroup) => {
    var module = '';
    if (this.props.module === 'program') {
      module = 'programListedBills';
    } else {
      module = 'listedBills';
    }
    var index = this.state[module].findIndex(
      (listedBill) => businessGroup.info.scGrupo === listedBill.info.scGrupo
    );
    this.state[module][index].collapsed =
      this.state[module][index].collapsed === '' ? 'collapsed' : '';
    this.setState({
      ...this.state,
    });
  };

  billsExcel = () => {
    const selectedCompany = this.state.scPagador;
    const token = this.props.authToken;
    this.setState({
      ...this.state,
      loading: true,
    });
    let scFondeador = '';
    let scGrupo = '';
    scFondeador = this.state.selectedInvestor.scFondeador;
    scGrupo = this.state.selectedBusinessGroup.scGrupo;
    billServices
      .listarOfertaExcel(
        token,
        selectedCompany,
        this.state.scProveedor,
        scFondeador,
        scGrupo
      )
      .then((response) => {
        const url = window.URL.createObjectURL(
          new Blob([response], { type: 'application/vnd.ms-excel' })
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `Preofertas.xlsx`);
        document.body.appendChild(link);
        link.click();
        this.setState({
          ...this.state,
          loading: false,
        });
      })
      .catch(() => {
        this.setState({
          ...this.state,
          redirect: true,
          loading: false,
        });
      });
  };

  componentWillUnmount() {
    clearTimeout(this.billsSelectionSectioIdTime);
  }

  render() {
    if (this.state.redirect) {
      return (
        <Redirect
          to={{
            pathname: `${process.env.PUBLIC_URL}/error`,
          }}
        />
      );
    }
    const { authToken } = this.props;
    var listedBills = [];
    listedBills = this.state.listedBills;
    const { investors } = this.state;
    var selectedInvestor = '';
    selectedInvestor = this.state.selectedInvestor;
    var selectedBusinessGroup = '';
    selectedBusinessGroup = this.state.selectedBusinessGroup;
    return (
      <div className='billing-style'>
        <div className='bills-style'>
          <Spin tip='Cargando...' spinning={this.state.loading}>
            {investors.length > 0 && (
              <div>
                <Card className='card-shadow card-radius default-border welcome-message '>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div className='title'>{BILLING_TITLE_INIT}</div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div className='subtitle'>{BILLING_SUBTITLE_INIT}</div>
                    </Col>
                  </Row>
                </Card>
                <Row gutter={[16, 16]}>
                  <Col span={24}>
                    <div className='title-info'>Elige el fondeador final</div>
                  </Col>
                </Row>
              </div>
            )}
            <Row gutter={[16, 16]} className='investors-container'>
              {investors.map((investor, key) => {
                return (
                  <Col
                    xl={{ span: 6 }}
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    key={investor.scFondeador + '-' + key}
                  >
                    <Card
                      ref={(cards) => (this.cards[key] = cards)}
                      className={
                        'investor-card card-radius default-border card-shadow ' +
                        (selectedInvestor.scFondeador === investor.scFondeador
                          ? ' active selected'
                          : '')
                      }
                      id={investor.scFondeador}
                      onClick={() => this.investorSelection(investor)}
                    >
                      <div className='title'>
                        {investor.nombreFondeador.toUpperCase()}
                      </div>
                      <div className={'logo-container'}>
                        <img
                          src={
                            investor.dsLogo
                              ? investor.dsLogo
                              : 'https://resources-affirmatum.s3.amazonaws.com/Pronto_Pago_MF.png'
                          }
                          alt=''
                          className='logo'
                        />
                      </div>
                      <div className='value'>
                        $ {this.formatNumber(investor.vrOfertaTotal)}
                      </div>
                      <div className='payers'>
                        {investor.grupoEmpresariales.map((grupoEmpresarial) => (
                          <p key={`${grupoEmpresarial.scGrupo}_key`}>
                            {grupoEmpresarial.dsNombres}
                          </p>
                        ))}
                      </div>
                      <div className='other-data'>
                        Desembolso: {investor.fechaDesembolso}
                      </div>
                      <div className='other-data'>
                        Nro. Facturas: {investor.nroFacturas}
                      </div>
                      <div className='conditions'>
                      <a href='#' onClick={() => this.handleOpenCondicionModal(investor)}>
                          VER CONDICIONES
                        </a>
                      </div>
                      {!investor.aprobado && (
                        <div className='disabled-investor'>
                          <p>{BILLING_TIME_ERROR}</p>
                        </div>
                      )}
                    </Card>
                  </Col>
                );
              })}
            </Row>

            {selectedInvestor !== '' && (
              <div id='bill-selection-section'>
                <Card className='card-shadow card-radius default-border welcome-message '>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div className='title'>
                        {selectedInvestor.nombreFondeador.toUpperCase()}
                      </div>
                    </Col>
                  </Row>
                  <Row gutter={[16, 16]}>
                    <Col span={24}>
                      <div
                        className='subtitle'
                        dangerouslySetInnerHTML={{
                          __html: selectedInvestor.mensajeFondeador,
                        }}
                      ></div>
                    </Col>
                  </Row>
                </Card>
                <Row gutter={30} className='bill-selection' align='middle'>
                  <Col>
                    <span className='title'>Elige facturas</span>
                  </Col>
                  <Col>
                    <div className='select-label'>
                      Filtrar por grupo empresarial:
                    </div>
                  </Col>
                  <Col span={6}>
                    <div className='select-container'>
                      <Select
                        size='small'
                        defaultValue={'all'}
                        value={selectedBusinessGroup.scGrupo}
                        onChange={this.businessGroupSelection}
                      >
                        <Option key={'all'} value={'all'}>
                          TODOS
                        </Option>
                        {selectedInvestor.grupoEmpresariales.map(
                          (grupoEmpresarial) => (
                            <Option
                              key={grupoEmpresarial.scGrupo}
                              value={grupoEmpresarial.scGrupo}
                            >
                              {grupoEmpresarial.dsNombres.toUpperCase()}
                            </Option>
                          )
                        )}
                      </Select>
                    </div>
                  </Col>
                  <Col span={2}>
                    <span className='button'>
                      <Button
                        size='small'
                        className='btn-excel'
                        onClick={this.billsExcel}
                        title='Exportar a excel'
                        icon={<FileExcelOutlined />}
                      ></Button>
                    </span>
                  </Col>
                </Row>
                {listedBills.map((businessGroup, key) => (
                  <Row
                    gutter={[16, 16]}
                    className='bill-table-container'
                    key={
                      key +
                      '-' +
                      selectedInvestor.scFondeador +
                      '-' +
                      businessGroup.info.scGrupo
                    }
                  >
                    <Col span={24}>
                      <Card className='card-shadow card-radius default-border card'>
                        <Row>
                          <Col
                            xl={{ span: 6 }}
                            md={{ span: 6 }}
                            className='client'
                          >
                            {businessGroup.info.dsNombres.toUpperCase()}
                          </Col>
                          <Col
                            xl={{ span: 5 }}
                            md={{ span: 6 }}
                            className='select-label'
                          >
                            Facturas/Notas crédito
                          </Col>
                          <Col
                            xl={{ span: 5 }}
                            md={{ span: 8 }}
                            className='switch-container'
                          >
                            <Switch
                              checkedChildren='Notas crédito'
                              checked={businessGroup.checked}
                              unCheckedChildren='Facturas'
                              onChange={() => this.changeTable(businessGroup)}
                            />
                          </Col>
                          <Col
                            xl={{ span: 8 }}
                            md={{ span: 4 }}
                            className='show-icon-container'
                          >
                            <CaretDownOutlined
                              className={
                                businessGroup.collapsed ? '' : 'caret-open'
                              }
                              onClick={() => this.openTable(businessGroup)}
                            />
                          </Col>
                        </Row>
                        <Divider />
                        <Row
                          gutter={[16, 16]}
                          className={
                            'table-collapse ' + businessGroup.collapsed
                          }
                        >
                          <Col span={24}>
                            {businessGroup.credits.length > 0 ||
                            businessGroup.bills.length > 0 ? (
                              <Table
                                className={
                                  businessGroup.checked ? 'no-check' : ''
                                }
                                pagination={{
                                  defaultPageSize: 10,
                                  showSizeChanger: true,
                                  pageSizeOptions: ['10', '20', '30'],
                                }}
                                columns={
                                  businessGroup.checked
                                    ? this.creditsColumns
                                    : this.billsColumns
                                }
                                dataSource={
                                  businessGroup.checked
                                    ? businessGroup.credits
                                    : businessGroup.bills
                                }
                              ></Table>
                            ) : (
                              <p className='empty-bills'>
                                Las facturas en oferta no cubren el valor de
                                notas crédito
                              </p>
                            )}
                          </Col>
                        </Row>
                        <Row className='footer'>
                          <Col className='text'>Hoy te ofrecemos:</Col>
                          <Col className='value value-offer'>
                            $ {this.formatNumber(businessGroup.totalOffer)}
                          </Col>
                          <Col className='text'>Notas crédito:</Col>
                          <Col className='value value-credit'>
                            $ {this.formatNumber(businessGroup.totalCredit)}
                          </Col>
                          <Col className='text text-total'>Total:</Col>
                          <Col className='value value-credit'>
                            ${' '}
                            {this.formatNumber(
                              businessGroup.totalOffer -
                                businessGroup.totalCredit
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                ))}
              </div>
            )}

            <Row gutter={[16, 16]}>
              <Col span={24} className='align-center'>
                <Button
                  className='btn-next'
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  Regresar
                </Button>
              </Col>
            </Row>
          </Spin>

          <CondicionesModal
          data={this.state.condicionDataFondeador}
          visible={this.state.condicionVisible}
          onClose={this.handleCondicionOnClose}
          token={authToken}
        />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  company: state.company.company,
  authToken: state.auth.auth.access_token,
});

export default withRouter(connect(mapStateToProps, {})(Bill));
