import { FileExcelOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Row, Spin, notification } from 'antd';
import React, { Component } from 'react';
import TableReport from '../../../../../components/tableReport/tableReport';
import {
  CurrencyFormat,
  percentageFormat,
  sorterDate,
  sorterText,
} from '../../../../../utilities/textTools';

import moment from 'moment';
import { listarMonedas } from '../../../../../services/admin/monedasService';
import { listarTiposConvenios } from '../../../../../services/admin/operation';
import {
  listarConvenios,
  listarGruposEmpresariales,
  listarPagadores,
  listarProveedores,
} from '../../../../../services/admin/reports/quotasService';
import {
  listaFacturasDescontadas,
  listaFacturasDescontadasExcel,
} from '../../../../../services/admin/reportsDiscountedBills';
import { setExcel } from '../../../../../utilities/downloadTools';
import TransactionInProgressFilterForm from './transactionInProgressFilterForm/transactionInProgressFilterForm';

const initialState = {
  form: {
    fechaDesdeCompra: moment().subtract(1, 'days'),
    fechaHastaCompra: moment(),
    scGrupo: '',
    scPagador: '',
    scProveedor: '',
    cdFactura: '',
    scConvenio: '',
    cdTipoConvenio: '',
    cdMoneda: 'COP',
  },
  loading: false,
  resultado: [],
  resumen: [
    {
      id: 'total',
      value: 'Total:',
      index: 'tipoConvenio',
      colSpan: 7,
    },
    {
      id: 'totalVrOfertas',
      value: '',
      index: 'vrOferta',
      colSpan: 3,
    },
  ],
  sort: '',
  currentPage: 1,
  offset: 0,
  limit: 10,
  total: 0,
  buttonOptions: [],
  grupoSelect: {
    fetching: false,
    options: [],
  },
  pagadorSelect: {
    fetching: false,
    options: [],
  },
  proveedorSelect: {
    fetching: false,
    options: [],
  },
  convenioSelect: {
    fetching: false,
    options: [],
  },
  tipoConvenioSelect: [],
  monedaSelect: [],
};

class TransactionInProgress extends Component {
  constructor(props) {
    super(props);

    this.state = { ...initialState };

    this.columnas = [
      {
        title: 'TIPO DE CONVENIO',
        dataIndex: 'tipoConvenio',
        sorter: (a, b) => sorterText(a.tipoConvenio, b.tipoConvenio),
        showSorterTooltip: false,
      },
      {
        title: 'FONDEADOR',
        dataIndex: 'nombreFondeador',
        sorter: (a, b) => sorterText(a.nombreFondeador, b.nombreFondeador),
        showSorterTooltip: false,
      },
      {
        title: 'PAGADOR',
        dataIndex: 'nombrePagador',
        sorter: (a, b) => sorterText(a.nombrePagador, b.nombrePagador),
        showSorterTooltip: false,
      },
      {
        title: 'PROVEEDOR',
        dataIndex: 'nombreProveedor',
        sorter: (a, b) => sorterText(a.nombreProveedor, b.nombreProveedor),
        showSorterTooltip: false,
      },
      {
        title: '# FACTURA',
        dataIndex: 'cdFactura',
        sorter: (a, b) => sorterText(a.cdFactura, b.cdFactura),
        showSorterTooltip: false,
      },
      {
        title: 'FECHA VENTA',
        dataIndex: 'feCompra',
        sorter: (a, b) => sorterDate(a.feCompra, b.feCompra),
        showSorterTooltip: false,
      },
      {
        title: 'TASA PROPORCIONAL',
        dataIndex: 'tasaProporcionalInf',
        render: (value) => <span>{percentageFormat(value)}</span>,
        sorter: (a, b) => a.tasaProporcionalInf - b.tasaProporcionalInf,
        showSorterTooltip: false,
      },
      {
        title: 'VALOR OFERTA',
        dataIndex: 'vrOferta',
        render: (value, record) => (
          <span>
            {CurrencyFormat({
              value: value,
              localizacion: record.localizacion,
              currency: record.cdMoneda,
            })}
          </span>
        ),
        sorter: (a, b) => a.vrOferta - b.vrOferta,
        showSorterTooltip: false,
      },
      {
        title: 'NÚMERO DE CUENTA',
        dataIndex: 'numeroCuenta',
        sorter: (a, b) => sorterText(a.numeroCuenta, b.numeroCuenta),
        showSorterTooltip: false,
      },
      {
        title: 'BANCO',
        dataIndex: 'nombreBanco',
        sorter: (a, b) => sorterText(a.nombreBanco, b.nombreBanco),
        showSorterTooltip: false,
      },
    ];
  }

  handleOnSearchPagador = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { pagadorSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...pagadorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarPagadores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          pagadorSelect: {
            ...pagadorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchProveedor = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { proveedorSelect } = this.state;

      this.setState({
        ...this.state,
        proveedorSelect: {
          ...proveedorSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarProveedores(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          proveedorSelect: {
            ...proveedorSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchConvenio = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { convenioSelect } = this.state;

      this.setState({
        ...this.state,
        convenioSelect: {
          ...convenioSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarConvenios(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          convenioSelect: {
            ...convenioSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleOnSearchGrupo = async (nombre) => {
    if (nombre && nombre.length >= 3) {
      const { grupoSelect } = this.state;

      this.setState({
        ...this.state,
        pagadorSelect: {
          ...grupoSelect,
          fetching: true,
        },
      });

      try {
        const response = await listarGruposEmpresariales(this.props.authToken, {
          nombre: nombre,
        });
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: response.data,
            fetching: false,
          },
        });
      } catch {
        this.setState({
          ...this.state,
          grupoSelect: {
            ...grupoSelect,
            options: [],
            fetching: false,
          },
        });
      }
    }
  };

  handleSubmit = (value) => {
    this.setState({
      ...this.state,
      form: {
        cdTipoConvenio: value.cdTipoConvenio,
        scGrupo: value.scGrupo,
        scConvenio: value.scConvenio,
        scPagador: value.scPagador,
        scProveedor: value.scProveedor,
        cdFactura: value.cdFactura,
        fechaDesdeCompra: value.fechaRango[0],
        fechaHastaCompra: value.fechaRango[1],
        cdMoneda: value.cdMoneda,
      },
      currentPage: initialState.currentPage,
      offset: initialState.offset,
    });

    this.searchData();
  };

  searchData = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        resultado: [],
        resumen: initialState.resumen,
        loading: true,
      });

      try {
        const response = await listaFacturasDescontadas(
          this.props.authToken,
          this.getFiltro()
        );

        const resumen = this.state.resumen.map((res) => {
          if (response.metadata.sumatorias[res.id] !== undefined) {
            res.value = (
              <span className='format-number'>
                {CurrencyFormat({
                  value: response.metadata.sumatorias[res.id],
                  localizacion: response.metadata.localizacion,
                  currency: response.metadata.cdMoneda,
                })}
              </span>
            );
          }

          return res;
        });

        this.setState({
          ...this.state,
          resultado: response.data,
          total: response.metadata.count,
          resumen: resumen,
          loading: false,
        });
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  exportExcel = async () => {
    if (this.validateForm()) {
      this.setState({
        ...this.state,
        loading: true,
      });

      try {
        const response = await listaFacturasDescontadasExcel(
          this.props.authToken,
          this.getFiltro()
        );
        setExcel(response, `reporte_facturas_en_tramite`);
        this.setState({
          ...this.state,
          loading: false,
        });
      } catch {
        this.setState({
          ...this.state,
          loading: false,
        });
      }
    }
  };

  getFiltro = () => {
    const { form, limit, offset, sort } = this.state;
    return {
      ...form,
      limit: limit,
      offset: offset,
      sort: sort,
    };
  };

  validateForm = () => {
    const fechaDesde = this.state.form.fechaDesdeCompra;
    const fechaHasta = this.state.form.fechaHastaCompra;

    if (
      fechaDesde === '' ||
      fechaDesde === null ||
      fechaHasta === '' ||
      fechaHasta === null
    ) {
      notification.error({
        message: 'Validacion de Campos',
        description: 'los campos fecha inicial y fecha final es obligatorio',
        duration: 4,
      });
      return false;
    }

    return true;
  };

  handleChange = (e) => {
    this.state.sort = e.sort;
    this.state.currentPage = e.currentPage;
    this.state.offset = e.offset;
    this.state.limit = e.limit;

    this.searchData();
  };

  init = async () => {
    try {
      const responseTipoConvenio = await listarTiposConvenios(
        this.props.authToken
      );
      const responseMoneda = await listarMonedas(this.props.authToken, {
        limit: 300,
      });
      this.setState({
        ...this.state,
        tipoConvenioSelect: responseTipoConvenio.data,
        monedaSelect: responseMoneda.data,
      });
    } catch {
      notification.error({
        message: 'Error',
        description: 'Se presento un error inesperado',
        duration: 5,
      });
    }
  };

  componentDidMount() {
    this.searchData();
    this.init();
  }

  render() {
    const {
      loading,
      currentPage,
      limit,
      total,
      resumen,
      resultado,
      grupoSelect,
      pagadorSelect,
      proveedorSelect,
      convenioSelect,
      tipoConvenioSelect,
      monedaSelect,
    } = this.state;

    return (
      <div className='reports-container'>
        <Spin tip='Cargando...' spinning={loading}>
          <TransactionInProgressFilterForm
            grupoSelect={grupoSelect}
            pagadorSelect={pagadorSelect}
            proveedorSelect={proveedorSelect}
            convenioSelect={convenioSelect}
            tipoConvenioSelect={tipoConvenioSelect}
            handleOnSearchConvenio={this.handleOnSearchConvenio}
            handleOnSearchProveedor={this.handleOnSearchProveedor}
            handleOnSearchGrupo={this.handleOnSearchGrupo}
            handleOnSearchPagador={this.handleOnSearchPagador}
            monedaSelect={monedaSelect}
            handleSubmit={this.handleSubmit}
          />
          <div className='table-container'>
            {this.state.resultado.length > 0 && (
              <Row gutter={30} className='bill-selection' justify='end'>
                <Col lg={{ span: 12 }} xl={{ span: 12 }} className='export'>
                  <span className='button'>
                    <Button
                      size='medium'
                      className='btn-excel'
                      title='Exportar a excel'
                      onClick={this.exportExcel}
                      icon={<FileExcelOutlined />}
                    ></Button>
                  </span>
                </Col>
              </Row>
            )}
            <Divider />
            <TableReport
              currentPage={currentPage}
              limit={limit}
              total={total}
              resumen={resumen}
              columns={this.columnas}
              data={resultado}
              handleChange={this.handleChange}
            />
          </div>
        </Spin>
      </div>
    );
  }
}

export default TransactionInProgress;
