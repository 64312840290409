import { Button, Card, Input, Spin,Form } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './consultation.scss';
import { exportarArchivoTransunion } from '../../../../services/admin/creditsService';
import { setExcel } from '../../../../utilities/downloadTools';
import {requiredValueMessage} from '../../../../utilities/admin_validation_messages';
import { totalLength } from '../../../../utilities/validate/textValidate';
import { exportarArchivoListaControl } from '../../../../services/admin/creditsService';

const SELECT_PLACE_HOLDER = 'Ingrese un nit...';

const rules = {
  nit: [
    { required: true, message: requiredValueMessage() },
    {
      pattern: new RegExp('^[0-9]+$'),
      message: 'El valor no tiene el formato correcto',
    },
    {
      validator: (_, value) => totalLength(value, 9),
    },
  ]
};

class Consultation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };

    this.formSearch = React.createRef();
  }

  ejecutarTarea = async (value) => {
    value.persist();
    try {
      const formValue = await this.formSearch.current.validateFields();

      this.setState({
        ...this.state,
        loading: true,
      });

      let res;
      let name;

      switch (value.target.innerHTML) {
        case 'Consulta Transunion':
          res = await exportarArchivoTransunion(this.props.authToken, formValue.idProveedor);  
          name = 'reporte_transunion';
          break;
       
        case 'Consultar Lista':
          res = await exportarArchivoListaControl(this.props.authToken, formValue.idProveedor);  
          name = 'reporte_lista_control';
         break;
     
       default:
        break;
      }
      setExcel(res, name);
      this.setState({
        ...this.state,
        loading: false,
      });
    } catch (errorInfo) {
      this.setState({
        ...this.state,
        loading: false,
      });
    }

  };

  render() {
    const { loading } = this.state;

    return (
      <Spin tip='Cargando...' spinning={loading}>
        <div className='consultation-style'>
          <Card className='card-shadow card-radius default-border welcome-message'>
            <Form ref={this.formSearch}>
              <div className='select-wrapper' style={{ width: '100%', marginBottom: '5rem' }}>
                <Form.Item
                  name='idProveedor'
                  label='NIT'
                  rules={rules['nit'] || ''}
                >
                  <Input
                    placeholder={SELECT_PLACE_HOLDER}
                    allowClear
                  ></Input>
                </Form.Item>
              </div>

              <div className='task-list'>
                <div className='task'>
                  <div className='task-label'>
                    <p className='task-label-text'>
                      ¿Desea que se ejecute la tarea, consultar en Transunion?
                    </p>
                  </div>
                  <div className='task-button'>
                  <Form.Item>
                    <Button
                      className='task-button-component'
                      onClick={this.ejecutarTarea}
                    >
                      Consulta Transunion
                    </Button>
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className='task-list'>
                <div className='task'>
                  <div className='task-label'>
                    <p className='task-label-text'>
                     ¿Desea que se consulte la empresa en la lista de control?
                    </p>
                  </div>
                  <div className='task-button'>
                  <Form.Item>
                    <Button
                      className='task-button-component'
                      onClick={this.ejecutarTarea}
                    >
                      Consultar Lista
                    </Button>
                    </Form.Item>
                  </div>
                </div>
                
              </div>
            </Form>
          </Card>
        </div>
      </Spin>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    authToken: state.auth.auth.access_token,
  };
};

export default connect(mapStateToProps)(Consultation);
